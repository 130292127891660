import {
  PaletteMode,
  ThemeOptions,
  alertTitleClasses,
  autocompleteClasses,
  listItemButtonClasses,
  listItemClasses,
  menuItemClasses
} from "@mui/material";
import {
  CheckCircleRounded,
  ErrorRounded,
  InfoRounded,
  WarningRounded
} from "@mui/icons-material";
import { LinkBehavior } from "@/components/elements/ReactRouterLink";
import { commonVariables, themeVariables } from "./themeVariables";
import React from "react";

const getThemedComponents = (
  mode: PaletteMode
): ThemeOptions["components"] => ({
  MuiIcon: {
    defaultProps: {
      baseClassName: "mf-icon"
    },
    styleOverrides: {
      root: {
        lineHeight: 1
      }
    }
  },
  MuiContainer: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        ...(!ownerState.disableGutters && {
          [theme.breakpoints.up("sm")]: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4)
          }
        })
      })
    }
  },
  MuiCssBaseline: {
    styleOverrides: (theme) => ({
      [".font-script"]: {
        fontFamily: "Noto sans !important"
      },
      [".pause-non-editable"]: {
        borderRadius: theme.shape.borderRadius * 1,
        padding: theme.spacing(0, 0.25),
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        cursor: "pointer",
        userSelect: "none"
      },
      [".mpro"]: {
        color: theme.palette.warning.dark,
        fontWeight: 500,
        cursor: "pointer"
      },
      [".memp"]: {
        boxShadow: "inset 0px 0px 0px 2px #84bb9c",
        padding: "1px 2px",
        borderRadius: theme.spacing(1)
      },
      [".emphasis-word-selector"]: {
        color: theme.palette.text.primary,
        height: "256px",
        border: "1px solid",
        borderColor: theme.palette.divider,
        fontSize: "16px",
        padding: "16px",
        cursor: "pointer",
        borderRadius: "4px"
      },
      [".selected-word"]: {
        backgroundColor: "#dfebff"
      },
      [".highlighted"]: {
        backgroundColor: theme.palette.warning.main
      }
    })
  },
  MuiLink: {
    defaultProps: {
      component: LinkBehavior,
      underline: "hover"
    }
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
      LinkComponent: LinkBehavior
    }
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true
    },
    styleOverrides: {
      root: ({ theme }) => ({
        textTransform: "none",
        borderRadius: theme.shape.borderRadius * 2
      })
    },
    variants: [
      {
        props: { size: "large" },
        style: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius * 3
        })
      },
      {
        props: { color: "brand", variant: "contained", disabled: false },
        style: ({ theme }) => ({
          background: commonVariables.primaryGradient,
          color: theme.palette.primary.contrastText,
          "&:hover": {
            boxShadow: commonVariables.primaryGradientHoverShadow
          }
        })
      },
      {
        props: { color: "secondary", variant: "contained" },
        style: ({ theme }) => ({
          color: theme.palette.secondary.dark,
          backgroundColor: theme.palette.secondaryButton.active,
          ":hover": {
            backgroundColor: theme.palette.secondaryButton.hover
          },
          ":focus-visible": {
            backgroundColor: theme.palette.secondaryButton.focus
          },
          ":disabled": {
            backgroundColor: theme.palette.secondaryButton.disabled,
            color: theme.palette.action.disabled
          }
        })
      }
    ]
  },
  MuiSkeleton: {
    styleOverrides: {
      rectangular: {
        borderRadius: 4
      }
    }
  },
  MuiChip: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        ...(ownerState.minimal
          ? {
              backgroundColor:
                ownerState.color === "default"
                  ? theme.palette.action.selected
                  : theme.palette[ownerState.color ?? "primary"].selected,
              color:
                ownerState.color === "default"
                  ? theme.palette.text.primary
                  : theme.palette[ownerState.color ?? "primary"].main
            }
          : {}),
        fontSize: 13,
        lineHeight: `1.38em`,
        letterSpacing: `0.01230em`,
        padding:
          ownerState.size === "small"
            ? `3px ${theme.spacing(0.5)}`
            : theme.spacing(0.5)
      }),
      label: {
        padding: "3px 6px"
      },
      labelSmall: {
        padding: "0 6px"
      },
      avatar: {
        margin: 0
      }
    }
  },
  MuiAppBar: {
    variants: [
      {
        props: { color: "default" },
        style: ({ theme }) => ({
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxShadow: "none"
        })
      }
    ]
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: commonVariables.tooltipBackground
      },
      arrow: {
        color: commonVariables.tooltipBackground
      }
    }
  },
  MuiToolbar: {
    styleOverrides: {
      root: ({ theme }) => ({
        [theme.breakpoints.up("sm")]: {
          minHeight: 64
        }
      })
    }
  },

  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: "none"
      },
      elevation: ({ ownerState, theme }) => {
        if (ownerState.elevation && ownerState.elevation > 3) {
          //* Used By Drawer, Dialog, Popover, Menu
          return {
            boxShadow: themeVariables[mode].shadows[3],
            backgroundColor: theme.palette.background.elevation3,
            backdropFilter: themeVariables[mode].backdropFilter[3]
          };
        }
        return undefined;
      },
      elevation1: ({ theme }) => ({
        boxShadow: themeVariables[mode].shadows[1],
        backgroundColor: theme.palette.background.elevation1,
        backdropFilter: themeVariables[mode].backdropFilter[1]
      }),
      elevation2: ({ theme }) => ({
        boxShadow: themeVariables[mode].shadows[2],
        backgroundColor: theme.palette.background.elevation2,
        backdropFilter: themeVariables[mode].backdropFilter[2]
      }),
      elevation3: ({ theme }) => ({
        boxShadow: themeVariables[mode].shadows[3],
        backgroundColor: theme.palette.background.elevation3,
        backdropFilter: themeVariables[mode].backdropFilter[3]
      })
    },
    variants: [
      {
        props: { variant: "outlined" },
        style: ({ theme }) => ({
          border: `1px solid ${theme.palette.divider}`
        })
      },
      {
        props: { variant: "outlined", dashed: true },
        style: ({ theme }) => ({
          border: `1px dashed ${theme.palette.divider}`
        })
      },
      {
        props: { hovered: true },
        style: {
          "&:hover": {
            boxShadow: themeVariables[mode].shadows[3],
            backgroundColor: themeVariables[mode].elevation[1]
          }
        }
      }
    ]
  },
  MuiDialog: {
    defaultProps: {
      PaperProps: {
        elevation: 3
      }
    },
    styleOverrides: {
      paper: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius * 4
      })
    }
  },
  MuiDrawer: {
    defaultProps: {
      elevation: 0
    },
    styleOverrides: {
      paper: ({ theme }) => ({
        backgroundColor: theme.palette.background.default
      })
    }
  },
  MuiMenu: {
    defaultProps: {
      elevation: 1
    },
    styleOverrides: {
      paper: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius * 2
      })
    }
  },
  MuiBackdrop: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(!ownerState.invisible
          ? { backgroundColor: themeVariables[mode].backdropBackground }
          : {})
      })
    }
  },
  MuiPopover: {
    defaultProps: {
      elevation: 1
    }
  },
  MuiListItemButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius * 1,
        "&:hover": {
          backgroundColor: theme.palette.secondary.hover
        },
        [`&.${listItemButtonClasses.focusVisible}`]: {
          backgroundColor: theme.palette.secondary.focusVisible
        },
        [`&.${listItemButtonClasses.selected}`]: {
          backgroundColor: theme.palette.secondary.selected,
          [`&.${listItemButtonClasses.focusVisible}`]: {
            backgroundColor: theme.palette.secondary.focusVisible
          },
          "&:hover": {
            backgroundColor: theme.palette.secondary.selected
          }
        }
      })
    }
  },
  MuiListItem: {
    styleOverrides: {
      selected: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius * 1,
        "&:hover": {
          backgroundColor: theme.palette.secondary.hover
        },
        [`&.${listItemClasses.focusVisible}`]: {
          backgroundColor: theme.palette.secondary.focusVisible
        },
        [`&.${listItemClasses.selected}`]: {
          backgroundColor: theme.palette.secondary.selected,
          [`&.${listItemClasses.focusVisible}`]: {
            backgroundColor: theme.palette.secondary.focusVisible
          },
          "&:hover": {
            backgroundColor: theme.palette.secondary.selected
          }
        }
      })
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius * 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        "&:hover": {
          backgroundColor: theme.palette.secondary.hover
        },
        [`&.${menuItemClasses.focusVisible}`]: {
          backgroundColor: theme.palette.secondary.focusVisible
        },
        [`&.${menuItemClasses.selected}`]: {
          backgroundColor: theme.palette.secondary.selected,
          [`&.${menuItemClasses.focusVisible}`]: {
            backgroundColor: theme.palette.secondary.focusVisible
          },
          "&:hover": {
            backgroundColor: theme.palette.secondary.selected
          }
        }
      })
    }
  },
  MuiAutocomplete: {
    styleOverrides: {
      listbox: ({ theme }) => ({
        [`& .${autocompleteClasses.option}`]: {
          ["&:hover"]: {
            backgroundColor: theme.palette.secondary.hover
          },
          [`&[aria-selected="true"]`]: {
            backgroundColor: theme.palette.secondary.selected
          },
          [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
            {
              backgroundColor: theme.palette.secondary.focusVisible
            }
        }
      })
    }
  },

  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius * 2
      })
    }
  },
  MuiList: {
    styleOverrides: {
      root: {
        padding: 4
      }
    }
  },
  MuiFab: {
    variants: [
      {
        props: { brand: true, color: "primary", disabled: false },
        style: () => ({
          backgroundImage: commonVariables.primaryGradient
        })
      }
    ]
  },
  MuiAlert: {
    defaultProps: {
      iconMapping: {
        info: React.createElement(InfoRounded),
        error: React.createElement(ErrorRounded),
        success: React.createElement(CheckCircleRounded),
        warning: React.createElement(WarningRounded)
      }
    },
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius * 2,
        paddingX: theme.spacing(2),
        paddingY: "6px",
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "143%" /* 20.02px */,
        letterSpacing: "0.17px",
        color: theme.palette.text.primary,
        [`& .${alertTitleClasses.root}`]: {
          fontSize: 16,
          fontWeight: 700,
          lineHeight: "150%" /* 24px */,
          letterSpacing: "0.15px"
        }
      }),
      standard: ({ theme, ownerState: { color, severity } }) => ({
        backgroundColor:
          theme.palette.alertComponent[color || severity || "success"],
        [`& .${alertTitleClasses.root}`]: {
          color: theme.palette[color || severity || "success"].main
        }
      }),
      filled: ({ theme, ownerState: { color, severity } }) => ({
        backgroundColor: theme.palette[color || severity || "success"].main,
        [`& .${alertTitleClasses.root}`]: {
          color: theme.palette[color || severity || "success"].contrastText
        }
      }),
      outlined: ({ theme, ownerState: { color, severity } }) => ({
        backgroundColor: theme.palette.background.elevation0,
        borderColor: theme.palette[color || severity || "success"].main,
        [`& .${alertTitleClasses.root}`]: {
          color: theme.palette[color || severity || "success"].main
        }
      })
    }
  }
});

export { getThemedComponents };
