import { MurfTab, MurfTabs } from "@/components/elements/MurfTabs";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { MediaPlayerProvider } from "@/features/mediaPlayer";
import { PreviewMediaPlayer } from "@/features/preview/components/PreviewMediaPlayer";
import {
  DUB_PREVIEW_MAIN_PLAYER_ID,
  DUB_PREVIEW_ORIGINAL_PLAYER_ID
} from "@/features/preview/constants/config";
import {
  PreviewPlayer,
  setPreviewPlayer
} from "@/reducers/slices/dubPreviewSlice/dubPreviewSlice";
import { Box, CircularProgress, Stack } from "@mui/material";
import { useMemo, useState } from "react";
import { TrackProps } from "react-player/file";

const Preview = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const currentPlayer = useTypedSelector(
    (state) => state.dubPreview.currentPlayer
  );
  const transcriptVttUrl = useTypedSelector(
    (state) => state.dubPreview.dubDetails.transcriptVttUrl
  );
  const dubbedVttUrl = useTypedSelector(
    (state) => state.dubPreview.dubDetails.dubbedVttUrl
  );

  const changePlayer = (player: PreviewPlayer) => {
    dispatch(setPreviewPlayer(player));
  };

  const tracks: TrackProps[] = useMemo(() => {
    const t = [];
    if (dubbedVttUrl && currentPlayer === PreviewPlayer.Dubbed) {
      t.push({
        src: dubbedVttUrl,
        kind: "subtitles",
        srcLang: "es",
        label: "Spanish",
        default: true
      });
    }
    if (transcriptVttUrl && currentPlayer === PreviewPlayer.Original) {
      t.push({
        src: transcriptVttUrl,
        kind: "subtitles",
        srcLang: "en",
        label: "English",
        default: true
      });
    }
    return t;
  }, [currentPlayer, dubbedVttUrl, transcriptVttUrl]);

  console.log({ tracks, transcriptVttUrl, dubbedVttUrl });

  return (
    <Stack
      width={"100%"}
      position={"relative"}
      pt={2}
      sx={{
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        height: "100%",
        width: "80%"
      }}
    >
      <MurfTabs
        value={currentPlayer}
        sx={{ marginBottom: 2 }}
        onChange={(_, value) => changePlayer(value)}
      >
        <MurfTab
          disableRipple
          value={PreviewPlayer.Original}
          label="Original"
        />
        <MurfTab disableRipple value={PreviewPlayer.Dubbed} label="Dubbed" />
      </MurfTabs>
      <Box
        sx={{
          backgroundColor: "background.default",
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          maxWidth: "80%"
        }}
      >
        <MediaPlayerProvider id={DUB_PREVIEW_MAIN_PLAYER_ID}>
          <MediaPlayerProvider id={DUB_PREVIEW_ORIGINAL_PLAYER_ID}>
            <PreviewMediaPlayer
              mediaPlayerProps={{
                onReady: () => {
                  setLoading(false);
                },
                onError: () => {
                  setLoading(false);
                },
                config: {
                  tracks
                }
              }}
            />
          </MediaPlayerProvider>
        </MediaPlayerProvider>
        {loading ? (
          <Stack
            height={"100%"}
            width={"100%"}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor: "background.default",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 3
            }}
          >
            <CircularProgress size={40} />
          </Stack>
        ) : null}
      </Box>
    </Stack>
  );
};

export default Preview;
