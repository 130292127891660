import { useState } from "react";
import { IconButton, InputAdornment, TextFieldProps } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import OutlinedTextField from "../OutlinedTextField";

/**
 * component for password fields
 * Gives option of password visibility
 */
const PasswordTextField = (props: TextFieldProps) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  return (
    <OutlinedTextField
      {...props}
      type={passwordVisible ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setPasswordVisible((show) => !show)}
              onMouseDown={(e) => e.preventDefault()}
              edge="end"
            >
              {passwordVisible ? (
                <Visibility fontSize="small" />
              ) : (
                <VisibilityOff fontSize="small" />
              )}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default PasswordTextField;
