import { PaletteOptions } from "@mui/material";
import { themeVariables } from "./themeVariables";

export interface MurfPaletteOptions {
  selected?: string;
  focusVisible?: string;
  outlinedBorder?: string;
  hover?: string;
}

export const lightThemePalette: PaletteOptions = {
  mode: "light",
  brand: {},
  primary: {
    main: "#6642B3",
    light: "#8D66DD",
    dark: "#42208A",
    contrastText: "#FFFFFF",
    hover: "#735DFF0F",
    selected: "#735DFF1A",
    focusVisible: "#735DFF4D",
    outlinedBorder: "#735DFF80"
  },
  secondary: {
    main: "#525266",
    dark: "#232132",
    light: "#6A6A81",
    contrastText: "#FFFFFF",
    hover: "#371D6C0A",
    selected: "#371D6C14",
    focusVisible: "#4F45663D",
    outlinedBorder: "#4F456666"
  },
  info: {
    main: "#5167F6",
    dark: "#5C4ACC",
    light: "#BDB4FA",
    contrastText: "#FFFFFF",
    hover: "#6E81FA1F",
    selected: "#6E81FA40",
    focusVisible: "#6E81FA66",
    outlinedBorder: "#6E81FA99"
  },
  success: {
    main: "#008F69",
    dark: "#006147",
    light: "#84DBC4",
    contrastText: "#FFFFFF",
    hover: "#008F691F",
    selected: "#008F6940",
    focusVisible: "#008F6966",
    outlinedBorder: "#008F6999"
  },
  error: {
    main: "#D60F3F",
    dark: "#941424",
    light: "#F57B8A",
    contrastText: "#FFFFFF",
    hover: "#FD2E471A",
    selected: "#FD2E4740",
    focusVisible: "#FD2E4766",
    outlinedBorder: "#FD2E4799"
  },
  warning: {
    main: "#F08035",
    dark: "#CA5818",
    light: "#FFD299",
    contrastText: "#FFFFFF",
    hover: "#FC874529",
    selected: "#FC874540",
    focusVisible: "#F0821466",
    outlinedBorder: "#F0821499"
  },
  action: {
    active: "#3F3F4D",
    hover: "#4B3A700F",
    selected: "#4B3A7014",
    focus: "#4B3A701F",
    disabledBackground: "#574F691F"
  },
  text: {
    primary: "#1D1136",
    secondary: "#1D113694",
    disabled: "#1D11364D"
  },
  divider: themeVariables.light.dividerColor,
  background: {
    default: themeVariables.light.backgroundDefault,
    paper: themeVariables.light.backgroundPaper,
    elevation0: themeVariables.light.elevation[0],
    elevation1: themeVariables.light.elevation[1],
    elevation2: themeVariables.light.elevation[2],
    elevation3: themeVariables.light.elevation[3]
  },
  elevation: {
    outlinedBorder: themeVariables.light.elevationOutline
  },
  common: {
    black: "#232132",
    white: "#FFFFFF"
  },
  secondaryButton: {
    active: "#1D11361A",
    hover: "#1D11362E",
    focus: "#1D113640",
    disabled: "#1D11360D"
  },
  alertComponent: {
    error: "#EC264A1A",
    warning: "#FB92001A",
    success: "#0099611A",
    info: "#286EFA1A"
  }
};
