import { styled, Tabs, tabsClasses } from "@mui/material";

export const MurfTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  alignItems: "center",
  minHeight: 38,
  [`& .${tabsClasses.flexContainer}`]: {
    position: "relative",
    padding: "0 4px",
    zIndex: 1
  },
  [`& .${tabsClasses.indicator}`]: {
    top: 3,
    bottom: 3,
    right: 3,
    height: "auto",
    boxShadow: "0 4px 12px 0 rgba(0,0,0,0.16)",
    borderRadius: theme.shape.borderRadius * 1,
    backgroundColor: theme.palette.secondaryButton.active
  }
}));

export default MurfTabs;
