import { LANGUAGES } from "@/constants/languages";
import { DUB_STATUS } from "@/types/project";

export enum FILE_ERROR_MSG {
  SPEECH_NOT_PRESENT = "SPEECH_NOT_PRESENT",
  LANGUAGE_NOT_SUPPORTED = "LANGUAGE_NOT_SUPPORTED"
}
// Also update uppyFactory for the supported formats
export const SUPPORTED_FORMATS =
  "video/mp4, video/mov, video/avi, video/mkv, audio/mp3, audio/wav, audio/aac";

export enum FILE_TYPES {
  VIDEO = "VIDEO",
  BG_AUDIO = "BG_AUDIO"
}

export enum DUB_ERROR_MSG {
  CREDITS_EXHAUSTED = "CREDITS_EXHAUSTED",
  INSUFFICIENT_CREDITS = "INSUFFICIENT_CREDITS"
}

export interface IDubTask {
  dubId: string;
  locale: LANGUAGES;
  dubStatus: DUB_STATUS;
  qcEnabled: boolean;
  sourceFileId: string;
  downloadUrl?: string | null;
  downloadZipUrl?: string | null;
  dueBy?: Date | number;
  deliveredOn?: Date | number;
  dubVersion?: string;
  errorMsg?: DUB_ERROR_MSG;
}

export interface IFileData {
  originalFileName: string;
  sourceFileId: string;
  thumbUrl: string | null;
  uploadedAt: Date | number;
  videoUrl: string | null;
  fileStatus?: string;
  type?: FILE_TYPES;
  contentType?: string;
  fileName?: string;
  duration?: number;
  fileType?: "VIDEO" | "BG_AUDIO";
  dubResponseList?: IDubTask[];
  archived?: boolean;
  errorMsg?: FILE_ERROR_MSG;
}

export interface SourceFileResponseList {
  [key: string]: IFileData;
}

export interface IClientProjectData {
  projectName: string;
  qualityCheckEnabled: boolean | undefined;
  lipSyncEnabled: boolean;
  currentStep: number;
  projectId?: string | undefined;
  targetLocales: LANGUAGES[];
  sourceLocale?: LANGUAGES;
  createdBy?: string;
  createdAt?: Date;
  dueBy?: Date;
  draft?: boolean;
  sourceFileResponseList?: SourceFileResponseList;
  numberOfVideos?: number;
  archived?: boolean;
  draftState?: string;
}

export type EMPTY_SCREEN_CASE = "NO_FILES_IN_FILTER" | "NO_FILES_ADDED";
