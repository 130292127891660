import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
  Zoom
} from "@mui/material";
import { LocalScriptBlockType } from "@/features/blocks";
import { PlayButtonBase } from "@/components/elements";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { getMSUsingLocaleString } from "@/utils/time";
import Editor from "@/features/editor";
import { ContentEditableEvent } from "react-contenteditable";
import {
  store,
  useAppDispatch,
  useTypedSelector
} from "@/config/configureAppStore";
import { useTranslation } from "@/features/scriptStudio/hooks/useTranslation";
import { TranslationPromptTypes } from "../constants";
import { STATUS } from "@/constants/status";
import { useSnackbar } from "@/components/elements/MurfSnackbar";
import { UNKNOWN_ERROR_MESSAGE } from "@/constants/errors";
import { useBlockAutoRender } from "@/features/scriptStudio/hooks/useBlockAutoRender";
import { EventHandlers } from "@/features/editor/hooks/useEditor";
import { sanitizeContentEditable } from "@/features/editor/util/richEditor";
import { InfoRounded } from "@mui/icons-material";
import { translationVoThunks } from "@/reducers/thunks/translationVo";

interface OriginalTextBlockProps {
  block: LocalScriptBlockType;
  isPlaying: boolean;
  handleBlockPlay: (isPlay: boolean) => void;
  textEditDisabled: boolean;
  isActive: boolean;
  eventHandlers: EventHandlers;
}

const OriginalTextBlock = ({
  block,
  isPlaying,
  handleBlockPlay,
  textEditDisabled,
  isActive,
  eventHandlers
}: OriginalTextBlockProps) => {
  const [translationStatus, setTranslationStatus] = useState(STATUS.IDLE);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const abortController = useRef<AbortController>();
  const dispatch = useAppDispatch();
  const originalBlock = block.original;
  const showReferenceLanguage = useTypedSelector(
    (state) => state.currentScriptProject.showReferenceLanguage
  );
  const durationString = useMemo(() => {
    return getMSUsingLocaleString(originalBlock.duration, true);
  }, [originalBlock.duration]);

  const { generateTranslation, updateTranslation } = useTranslation();
  const renderBlock = useBlockAutoRender();
  const { showError } = useSnackbar();

  const handleChange = useCallback(
    (e: ContentEditableEvent) => {
      if (translationStatus === STATUS.LOADING)
        abortController.current?.abort();
      const text: string = e.target?.value || e.currentTarget.innerHTML || "";

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        dispatch(
          translationVoThunks.updateTranscriptionBlock({
            blocks: {
              [block.blockId]: {
                localText: showReferenceLanguage ? undefined : text,
                localEnglishText: showReferenceLanguage ? text : undefined
              }
            }
          })
        );
      }, 500);
    },
    [block.blockId, dispatch, translationStatus, showReferenceLanguage]
  );

  const getTranslation = () => {
    const updatedBlock =
      store.getState().projectUtility.blocksMap[block.blockId];
    setTranslationStatus(STATUS.LOADING);
    abortController.current = new AbortController();
    generateTranslation({
      block: updatedBlock,
      prompt: "",
      prompts: [TranslationPromptTypes.BASE_TRANSLATION],
      useLocalText: false,
      useEnglishText: showReferenceLanguage,
      abortController: abortController.current
    })
      .then((res) => {
        if (res) {
          updateTranslation({
            text: res[0],
            addToList: false,
            blockId: block.blockId
          });
          renderBlock(block.blockId);
        }

        setTranslationStatus(STATUS.SUCCESS);
      })
      .catch((err) => {
        showError(err?.message || UNKNOWN_ERROR_MESSAGE);
        setTranslationStatus(STATUS.ERROR);
      });
  };

  const handleAction = (save?: boolean) => {
    if (save) {
      dispatch(
        translationVoThunks.updateTranscriptionBlock({
          blocks: { [block.blockId]: {} },
          save: true
        })
      ).then(() => {
        getTranslation();
      });
    } else {
      getTranslation();
    }
  };

  const handleEditCancel = () => {
    dispatch(
      translationVoThunks.updateTranscriptionBlock({
        blocks: {
          [block.blockId]: {
            localText: showReferenceLanguage ? undefined : originalBlock.text,
            localEnglishText: showReferenceLanguage
              ? originalBlock.englishText
              : undefined
          }
        }
      })
    );
  };

  const transcriptionEventHandlers = useMemo(
    () => ({
      onChange: handleChange,
      onFocus: eventHandlers.onFocus
    }),
    [handleChange, eventHandlers.onFocus]
  );

  let text = block.original.text;
  let localText = originalBlock.localText;
  let textUpdatedAt = originalBlock.textUpdatedAt;
  let textSyncedAt = block.transcriptionUpdatedAt;

  if (showReferenceLanguage) {
    localText = originalBlock.localEnglishText || "";
    text = originalBlock.englishText || "";
    textUpdatedAt = originalBlock.englishTextUpdatedAt;
    textSyncedAt = block.englishTextUpdatedAt ?? 0;
  }

  const isEmptyText = useMemo(
    () => !sanitizeContentEditable(localText).trim().length,
    [localText]
  );

  const localTranscriptionChanged = localText !== text;

  const outdatedTranslation = textUpdatedAt > textSyncedAt;

  const transcriptionChanged = outdatedTranslation || localTranscriptionChanged;

  const isTranslating = translationStatus === STATUS.LOADING;

  return (
    <Stack width="47%">
      <Stack direction="row" alignItems="center" gap={1}>
        <PlayButtonBase
          isPlaying={isPlaying}
          play={() => handleBlockPlay(true)}
          pause={() => handleBlockPlay(false)}
        />
        <Typography color="text.secondary" variant="caption">
          {durationString}
        </Typography>
        {outdatedTranslation && !isTranslating ? (
          <Box display="flex" textAlign="center" gap={1}>
            <Typography
              noWrap
              component="p"
              variant="caption"
              color="secondary"
            >
              Transcription was changed for this video
            </Typography>
            <Tooltip title="Click on translate to get the new translation">
              <InfoRounded fontSize="small" color="secondary" />
            </Tooltip>
          </Box>
        ) : null}
      </Stack>

      <Editor
        text={localText}
        index={block.blockNumber - 1}
        eventHandlers={transcriptionEventHandlers}
        translationEditor={false}
        blockId={block.blockId}
        disabled={textEditDisabled}
        isActive={!textEditDisabled && (isActive || transcriptionChanged)}
      />
      <Box display="flex" justifyContent="start" height={38}>
        {!textEditDisabled && transcriptionChanged ? (
          <Zoom in>
            <Box display="flex" height={38} gap={1} marginTop={1}>
              <Tooltip
                title={
                  isEmptyText ? "Please enter something to translate." : ""
                }
              >
                <span>
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    sx={{ fontSize: { md: 10, lg: 14 } }}
                    startIcon={
                      isTranslating ? (
                        <CircularProgress size={14} color="secondary" />
                      ) : null
                    }
                    disabled={isTranslating || isEmptyText}
                    onClick={() => handleAction(localTranscriptionChanged)}
                  >
                    {localTranscriptionChanged
                      ? "Save & Translate"
                      : "Translate"}
                  </Button>
                </span>
              </Tooltip>
              {localTranscriptionChanged ? (
                <span>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={handleEditCancel}
                    sx={{ fontSize: { md: 10, lg: 14 } }}
                  >
                    Cancel
                  </Button>
                </span>
              ) : null}
            </Box>
          </Zoom>
        ) : null}
      </Box>
    </Stack>
  );
};

export default memo(OriginalTextBlock);
