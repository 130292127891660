import { Outlet } from "react-router-dom";
import LoaderWrapper from "@/components/layout/LoaderWrapper";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { STATUS } from "@/constants/status";
import { useEffect } from "react";
import { userThunks } from "@/reducers/thunks/user";
import { MurfSnackbar } from "@/components/elements/MurfSnackbar";

const Internal = () => {
  const { apiStatus } = useTypedSelector((state) => state.user);

  const dispatch = useAppDispatch();
  useEffect(() => {
    const promise = dispatch(userThunks.fetchInternalUsers());
    return () => {
      promise.abort();
    };
  }, [dispatch]);

  return (
    <LoaderWrapper loading={apiStatus !== STATUS.SUCCESS}>
      <Outlet />
      <MurfSnackbar />
    </LoaderWrapper>
  );
};

export default Internal;
