import { LANGUAGES } from "@/constants/languages";
import { userThunks } from "@/reducers/thunks/user";
import { STATUS } from "@/constants/status";
import {
  IWorkspace,
  PendingInviteUsers,
  WorkspaceUser
} from "@/types/workspace";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MURF_ROLES, TUser } from "@/features/user";
import { TASK_TYPE } from "@/types/project";
import { START_FLOW_COMPLETED, storageService } from "@/utils/storage";
import { PLANS_CATEGORY } from "@/features/pricing/types";

export interface SupportedLocale {
  locale: string;
  credit: number;
}
export interface WorkspaceData extends IWorkspace {
  users: Record<string, WorkspaceUser>;
  dubPlan: string;
  planCategory: PLANS_CATEGORY;
  supportedSourceLocales: SupportedLocale[];
  supportedQCTargetLocales: SupportedLocale[];
  supportedNonQCTargetLocales: SupportedLocale[];
}

export interface UserSliceState {
  userId: string;
  firstName: string;
  lastName: string;
  role: MURF_ROLES;
  activeWorkspaceId: string | null;
  startFlowCompleted: boolean;
  linkedWorkspaces: IWorkspace[];
  apiStatus: STATUS;
  displayName: string;
  workspaceApiStatus: STATUS;
  activeWorkspaceData: WorkspaceData | null;
  languages: LANGUAGES[];
  specialisation: TASK_TYPE[];
  countryCode?: string;
  regionCode?: string;
  profileImageUrl?: string | null;
  bouncerData: null | {
    bouncerStatus: string;
    domainProvider: string;
    reason: string;
  };
}

const initialState: UserSliceState = {
  userId: "",
  firstName: "",
  lastName: "",
  role: MURF_ROLES.USER,
  apiStatus: STATUS.IDLE,
  activeWorkspaceId: null,
  startFlowCompleted: false,
  linkedWorkspaces: [],
  displayName: "",
  languages: [],
  specialisation: [],
  workspaceApiStatus: STATUS.IDLE,
  activeWorkspaceData: null,
  bouncerData: null,
  profileImageUrl: null,
  countryCode: storageService.getItem("countryCode") || "US",
  regionCode: storageService.getItem("regionCode") || undefined
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateActiveWorkspaceId: (
      state,
      action: PayloadAction<{ workspaceId: string }>
    ) => {
      const workspaceId = action.payload.workspaceId;
      window.activeWorkspaceId = workspaceId;
      state.activeWorkspaceId = workspaceId;
    },
    updateBouncerData: (
      state,
      action: PayloadAction<{
        bouncerStatus: string;
        domainProvider: string;
        reason: string;
      }>
    ) => {
      state.bouncerData = action.payload;
    },
    updateUserData: (state, action: PayloadAction<Partial<TUser>>) => {
      state.firstName = action.payload.firstName ?? state.firstName;
      state.lastName = action.payload.lastName ?? state.lastName;
    },
    updatePendingInviteUsers: (
      state,
      action: PayloadAction<PendingInviteUsers>
    ) => {
      if (state.activeWorkspaceData) {
        state.activeWorkspaceData.pendingInvitationsEmails = {
          ...state.activeWorkspaceData?.pendingInvitationsEmails,
          ...action.payload
        };
      }
    },
    setPendingInviteUsers: (
      state,
      action: PayloadAction<PendingInviteUsers>
    ) => {
      if (state.activeWorkspaceData) {
        state.activeWorkspaceData.pendingInvitationsEmails = action.payload;
      }
    },
    setStartFlowCompleted: (state, action: PayloadAction<boolean>) => {
      state.startFlowCompleted = action.payload;
    },
    removeFromUserList: (state, action: PayloadAction<string>) => {
      const emailId = action.payload;
      // removing user from workspace users list
      if (state.activeWorkspaceData?.users) {
        const user = Object.values(state.activeWorkspaceData.users).find(
          (user) => user.email === emailId
        );

        if (user) delete state.activeWorkspaceData.users[user.uid];
      }
    },
    updateWorkspaceData: (
      state,
      action: PayloadAction<Partial<IWorkspace>>
    ) => {
      const workspaceId = action.payload.workspaceId;
      if (workspaceId) {
        // if updating workspace is active workspace then update it
        if (state.activeWorkspaceData?.workspaceId === workspaceId) {
          state.activeWorkspaceData = {
            ...state.activeWorkspaceData,
            ...action.payload
          };
        }
        // find workspace in linked workspaces and then update it
        state.linkedWorkspaces.map((workspace, ind) => {
          if (workspace.workspaceId === workspaceId) {
            state.linkedWorkspaces[ind] = {
              ...state.linkedWorkspaces[ind],
              ...action.payload
            };
          }
        });
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userThunks.fetchUserState.fulfilled, (state, action) => {
      const workspaceId =
        action.payload.activeWorkspaceId ||
        action.payload.linkedWorkspaces[0]?.workspaceId;
      state.userId = action.payload.userId;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.displayName = `${action.payload.firstName || ""} ${
        action.payload.lastName || ""
      }`;
      state.profileImageUrl = action.payload.profileImageUrl;
      state.activeWorkspaceId = workspaceId;
      state.startFlowCompleted =
        action.payload.startFlowCompleted ||
        Boolean(storageService.getItem(START_FLOW_COMPLETED));
      window.activeWorkspaceId = workspaceId;
      state.linkedWorkspaces = action.payload.linkedWorkspaces;
      state.apiStatus = STATUS.SUCCESS;
      state.role =
        (action.payload.murfRole as string) === "DEVELOPER"
          ? MURF_ROLES.ADMIN
          : action.payload.murfRole;
      state.specialisation = action.payload.specialisation ?? [];
      state.languages = action.payload.languages ?? [];
    });
    builder.addCase(userThunks.fetchUserState.pending, (state) => {
      state.apiStatus = STATUS.LOADING;
    });
    builder.addCase(userThunks.fetchUserState.rejected, (state) => {
      state.apiStatus = STATUS.ERROR;
    });
    builder
      .addCase(userThunks.fetchWorkspaceState.pending, (state) => {
        state.workspaceApiStatus = STATUS.LOADING;
      })
      .addCase(userThunks.fetchWorkspaceState.fulfilled, (state, action) => {
        state.workspaceApiStatus = STATUS.SUCCESS;
        state.activeWorkspaceData = action.payload;
      })
      .addCase(userThunks.fetchWorkspaceState.rejected, (state) => {
        state.workspaceApiStatus = STATUS.ERROR;
      });
    builder.addCase(
      userThunks.fetchVisitorRegion.fulfilled,
      (state, action) => {
        const countryCode = action.payload.countryCode;
        const regionCode = action.payload.regionCode;
        state.countryCode = countryCode;
        state.regionCode = regionCode;
        window.countryCode = countryCode;
        window.regionCode = regionCode;
        storageService.setItem("countryCode", countryCode);
        storageService.setItem("regionCode", regionCode);
        return state;
      }
    );
  }
});

export const {
  updateActiveWorkspaceId,
  updateBouncerData,
  updateUserData,
  updatePendingInviteUsers,
  setPendingInviteUsers,
  removeFromUserList,
  updateWorkspaceData,
  setStartFlowCompleted
} = userSlice.actions;

export default userSlice.reducer;
