import { PaletteOptions } from "@mui/material";
import { themeVariables } from "./themeVariables";

export const darkThemePalette: PaletteOptions = {
  mode: "dark",
  brand: {},
  primary: {
    main: "#6642B3",
    light: "#BEA0FA",
    dark: "#532F9E",
    contrastText: "#FFFFFF",
    hover: "#735DFF14",
    selected: "#735DFF40",
    focusVisible: "#735DFF4D",
    outlinedBorder: "#735DFF80"
  },
  secondary: {
    main: "#CECEDB",
    light: "#9A9AB2",
    dark: "#FFFFFF",
    contrastText: "#232132",
    hover: "#AEA8D414",
    selected: "#AEA8D429",
    focusVisible: "#AEA8D44D",
    outlinedBorder: "#AEA8D459"
  },
  info: {
    main: "#6666FF",
    light: "#A0A0FF",
    dark: "#3939CC",
    contrastText: "#FFFFFF",
    hover: "#656FFC1F",
    selected: "#656FFC40",
    focusVisible: "#656FFC66",
    outlinedBorder: "#656FFC99"
  },
  success: {
    main: "#07A379",
    light: "#85DDBD",
    dark: "#005C43",
    contrastText: "#FFFFFF",
    hover: "#07A3791F",
    selected: "#07A37940",
    focusVisible: "#07A37966",
    outlinedBorder: "#07A37999"
  },
  error: {
    main: "#EB4A47",
    light: "#F57B8A",
    dark: "#941424",
    contrastText: "#FFFFFF",
    hover: "#FD2E471A",
    selected: "#FD2E4740",
    focusVisible: "#FD2E4766",
    outlinedBorder: "#FD2E4799"
  },
  warning: {
    main: "#F08035",
    light: "#FFCA80",
    dark: "#CA5818",
    contrastText: "#FFFFFF",
    hover: "#FC874529",
    selected: "#FC874540",
    focusVisible: "#FB920066",
    outlinedBorder: "#FB920099"
  },
  action: {
    active: "#F7F7F7",
    hover: "#C5C0D11F",
    selected: "#C5C0D124",
    focus: "#C5C0D129",
    disabledBackground: "#F8F5FF1F"
  },
  text: {
    primary: "#F1F0F5",
    secondary: "#FFFFFF99",
    disabled: "#FFFFFF4D"
  },
  divider: themeVariables.dark.dividerColor,
  background: {
    default: themeVariables.dark.backgroundDefault,
    paper: themeVariables.dark.backgroundPaper,
    elevation0: themeVariables.dark.elevation[0],
    elevation1: themeVariables.dark.elevation[1],
    elevation2: themeVariables.dark.elevation[2],
    elevation3: themeVariables.dark.elevation[3]
  },
  elevation: {
    outlinedBorder: themeVariables.dark.elevationOutline
  },
  common: {
    black: "#232132",
    white: "#FFFFFF"
  },
  secondaryButton: {
    active: "#d6ccff26",
    hover: "#d6ccff2e",
    focus: "#d6ccff40",
    disabled: "#d6ccff0d"
  },
  alertComponent: {
    error: "#EC264A1A",
    warning: "#FB92001A",
    success: "#0099611A",
    info: "#286EFA1A"
  }
};
