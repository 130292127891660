import { ReactGA } from "murflib";

export const trackGA4Event = (
  event: string,
  properties = {},
  non_interaction = false
) => {
  ReactGA.eventGA4(
    event,
    {
      ...properties,
      product_type: "DUB"
    },
    non_interaction
  );
};
