import { JSON_TYPE, TEXT_TYPE, api } from "@/lib/axios";
import { APIResponse } from "@/types/apiResponse";
import { InternalWorkspace, WORKSPACE_ROLES } from "@/types/workspace";

interface InviteUserParams {
  emailId: string;
  role: WORKSPACE_ROLES;
}

interface AcceptInviteParams {
  emailId: string;
  workspaceId: string;
  hmac: string;
}

interface AcceptMemberInvite {
  emailId: string;
  hmac: string;
}

interface UpdateWorkspacePayload {
  displayName: string;
}

interface UpdateWorkspacePlanPayload {
  planType?: string;
  credits?: number;
  expiryDateMillis?: number;
  workspaceId: string;
}

interface AddDeliveryManagersParams {
  workspaceId: string;
  deliveryManagers: string[];
}

export enum AllowedFeature {
  QUALITY_CHECK = "quality-check",
  LIP_SYNC = "lip-sync",
  DISABLE_AUTOMATE_DUB = "disable-automate-dub"
}

interface UpdateAllowedFeatureParams {
  workspaceId: string;
  isAllowed: boolean;
  feature: AllowedFeature;
}

export type Invoice = {
  amount: number;
  created: number;
  currency: string;
  date: string;
  link: string;
  refunded: number;
  id: string;
};

export const workspaceAdminAPI = {
  createWorkspace: (data: {
    displayName: string;
    ownerEmail: string;
    deliveryManagers: string[];
    plan: {
      planType: string;
      credits: number;
      expiryDateMillis: number;
    };
    lipSyncEnabled: boolean;
    qcEnabled: boolean;
    disableAutomatedDub: boolean;
  }) =>
    api.put<APIResponse<any>>(
      `admin/workspace/create`,
      {
        ...data
      },
      {
        headers: {
          "Content-Type": JSON_TYPE
        }
      }
    ),
  addDeliveryManagers: ({
    workspaceId,
    deliveryManagers
  }: AddDeliveryManagersParams) =>
    api.post<APIResponse<any>>(`admin/workspace/add-member/${workspaceId}`, {
      deliveryManagers
    }),
  updateAllowedFeatures: ({
    workspaceId,
    isAllowed,
    feature
  }: UpdateAllowedFeatureParams) =>
    api.put<APIResponse<any>>(
      `admin/workspace/update-value/${feature}/${workspaceId}`,
      isAllowed,
      {
        headers: {
          "Content-Type": JSON_TYPE
        }
      }
    ),
  fetchWorkspaces: () =>
    api.get<APIResponse<InternalWorkspace[]>>("admin/workspace/all-workspace"),
  getWorkspace: (workspaceId: string) =>
    api.get<APIResponse<any>>(`admin/workspace/${workspaceId}`),
  inviteUser: ({ emailId, role }: InviteUserParams) =>
    api.post("/workspace/invite-to-workspace", { emailId, role }),
  acceptInvite: ({ emailId, hmac, workspaceId }: AcceptInviteParams) =>
    api.post<APIResponse<string>>(
      `ping/accept-follow-invite/${emailId}/${workspaceId}/${hmac}`
    ),
  acceptMemberInvite: ({ emailId, hmac }: AcceptMemberInvite) =>
    api.post<APIResponse<string>>(
      `ping/accept-member-invite/${emailId}/${hmac}`
    ),
  removeUserFromWorkspace: (emailId: string) =>
    api.post(`/workspace/remove-linked-account`, emailId, {
      headers: {
        "Content-Type": TEXT_TYPE
      }
    }),
  updateWorkspace: (payload: UpdateWorkspacePayload) =>
    api.post<APIResponse>("workspace/update", payload),
  updateWorkspacePlan: ({
    workspaceId,
    ...payload
  }: UpdateWorkspacePlanPayload) =>
    api.put<APIResponse>(`admin/workspace/update-plan/${workspaceId}`, payload),
  getAllUsersOfWorkspace: (workspaceId: string) =>
    api.get(`/workspace/workspace-user/${workspaceId}`),
  getInternalMembers: (userIds: string[]) =>
    api.post(`workspace/fetch-member-details`, userIds),
  fetchCustomerPortal: () =>
    api.get<
      APIResponse<{
        portalUrl: string;
        receipts: Invoice[];
      }>
    >("/stripe/portal")
};
