import LoadingButton from "@/components/elements/LoadingButton";
import PasswordTextField from "@/components/elements/PasswordTextField";
import { STATUS } from "@/constants/status";
import { Key } from "@mui/icons-material";
import { Box, Paper, Typography } from "@mui/material";
import { useState } from "react";

interface SharePasswordProps {
  openPasswordLink: (password: string) => void;
  status: STATUS;
}

const SharePassword = ({ openPasswordLink, status }: SharePasswordProps) => {
  const [password, setPassword] = useState("");

  const isLoading = status === STATUS.LOADING;
  const isFailed = status === STATUS.ERROR;

  return (
    <Paper
      elevation={0}
      sx={{
        background: "default",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 5,
        padding: 6,
        paddingTop: 5,
        borderRadius: 6
      }}
    >
      <Box
        sx={{
          borderRadius: "50%",
          height: 64,
          width: 64,
          backgroundColor: "info.selected",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Key sx={{ height: 32, width: 32 }} color="info" />
      </Box>
      <Box textAlign="center">
        <Typography variant="h5">This link is password protected</Typography>
        <Typography variant="body1">
          You need to enter password to view Youtube video project
        </Typography>
      </Box>

      <PasswordTextField
        label="Enter password"
        fullWidth
        placeholder="Enter your password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        helperText={isFailed ? "Password incorrect" : ""}
        FormHelperTextProps={{
          color: "error"
        }}
        error={isFailed}
      />
      <LoadingButton
        variant="contained"
        size="large"
        fullWidth
        onClick={() => openPasswordLink(password)}
        disabled={isLoading}
        loading={isLoading}
      >
        Continue
      </LoadingButton>
    </Paper>
  );
};

export default SharePassword;
