import { separateNumericPart } from "@/utils/misc";
import { Typography } from "@mui/material";
import { useMemo } from "react";

interface MurfVersionProps {
  dubVersion: string;
  dubVersionNumber?: string;
}

const MurfVersion = ({ dubVersion, dubVersionNumber }: MurfVersionProps) => {
  dubVersionNumber = useMemo(
    () => dubVersionNumber || separateNumericPart(dubVersion, true)[1],
    [dubVersionNumber, dubVersion]
  );

  return (
    <Typography
      variant="body2"
      color="text.secondary"
      sx={{
        backgroundColor: "background.paper",
        paddingX: 1,
        paddingY: 0.5,
        borderRadius: 1
      }}
    >
      v
      <Typography color="text.primary" variant="subtitle2" display="inline">
        {dubVersionNumber}
      </Typography>
    </Typography>
  );
};

export default MurfVersion;
