import { useAppDispatch } from "@/config/configureAppStore";
import { setPricingDialog } from "@/reducers/slices/pricingDialogSlice/pricingDialogSlice";
import { storageService } from "@/utils/storage";
import { useSearchParams } from "react-router-dom";

const usePricingDialog = () => {
  const [, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const closePricingPopup = () => {
    setSearchParams((prev) => {
      prev.delete("intent");
      storageService.removeItem("showPricingDialog");
      prev.delete("planCategory");
      storageService.removeItem("showPricingDialogForPlanId");
      return prev;
    });
    dispatch(setPricingDialog(false));
  };

  return {
    closePricingPopup
  };
};

export default usePricingDialog;
