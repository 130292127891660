import { PricingPlan } from "@/features/pricing/types";
import { pricingThunks } from "@/reducers/thunks/pricingThunks";
import { userThunks } from "@/reducers/thunks/user";
import { workspaceThunk } from "@/reducers/thunks/workspaceThunk";
import { convertArrayToMap } from "@/utils/json";
import { createSlice } from "@reduxjs/toolkit";

export interface WorkspaceSliceState {
  userList: Record<string, any>;
  internalMembers: Record<string, any>;
  workspacePlan: {
    planDetails: PricingPlan | undefined;
    useStripeIndia: boolean;
    portalUrl?: string;
  };
}

export const workspaceSlice = createSlice({
  name: "workspace",
  initialState: {
    userList: {},
    workspacePlan: {
      planDetails: undefined,
      useStripeIndia: false
    }
  } as WorkspaceSliceState,
  reducers: {
    setPortalUrl: (state, action: { payload: string }) => {
      state.workspacePlan.portalUrl = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        workspaceThunk.getAllUsersOfWorkspace.fulfilled,
        (state, action) => {
          const { workspaceId, users, pendingInvitationsEmails } =
            action.payload.responseData;
          state.userList[workspaceId] = {
            users,
            pendingInvitationsEmails,
            workspaceId
          };
        }
      )
      .addCase(workspaceThunk.getInternalMembers.fulfilled, (state, action) => {
        const users = action.payload.responseData;
        state.internalMembers = {
          ...state.internalMembers,
          ...convertArrayToMap(users, "userId")
        };
      })
      .addCase(userThunks.fetchWorkspaceState.fulfilled, (state, action) => {
        const workspaceState = action.payload;
        const autoPayData = workspaceState?.autoPay;
        state.workspacePlan = {
          planDetails: {
            category: workspaceState.planCategory,
            planId: workspaceState.dubPlan,
            noOfProject: workspaceState.noOfProject || 1,
            selfEditAllowed: workspaceState.selfEditAllowed,
            qualityAssurance: workspaceState.qualityAssurance,
            noWaterMark: workspaceState.noWaterMark,
            maxUser: workspaceState.maxUser,
            autoPayEnabled: autoPayData?.enabled || false,
            autoPayLowerLimit: autoPayData?.balanceThreshold || undefined,
            autoPayUpperLimit: autoPayData?.balanceUpperLimit || undefined,
            lastAutoPayTriggerCalledMillis:
              autoPayData?.lastAutoPayTriggerCalledMillis || 0,
            autoPayStatus: autoPayData?.status
          },
          useStripeIndia: workspaceState.useStripeIndia || false
        };
      })
      .addCase(pricingThunks.updateAutoPayData.fulfilled, (state, action) => {
        if (state.workspacePlan.planDetails) {
          const planDetails: Partial<typeof state.workspacePlan.planDetails> = {
            autoPayEnabled: action.payload.autoPayEnabled,
            autoPayLowerLimit: action.payload.autoPayLowerLimit || undefined,
            autoPayUpperLimit: action.payload.autoPayUpperLimit || undefined
          };

          state.workspacePlan.planDetails = {
            ...state.workspacePlan.planDetails,
            ...planDetails
          };
        }

        return state;
      });
  }
});
export default workspaceSlice.reducer;
