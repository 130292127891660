import React, { useRef } from "react";
import {
  seekTo,
  setSeeking,
  setProgress,
  useMediaPlayer,
  requestFullScreen,
  toggleSubtitles
} from "@/features/mediaPlayer";
import { getSeekerTime } from "@/utils/time";
import {
  ClosedCaptionOff,
  ClosedCaptionRounded,
  FullscreenRounded
} from "@mui/icons-material";
import { IconButton, Slider, Stack, Typography } from "@mui/material";
import { PlayButton } from "../..";
import { PlayerVolumeControls } from "./PlayerVolumeControls";

interface PlayerControlsProps {
  showFullScreen: boolean;
  audioPlayerId: string;
  seekerMarks?: {
    value: number;
    label: JSX.Element;
  }[];
  enableCustomFullScreenControls?: boolean;
  subtitlesAvailable?: boolean;
}

const PlayerControls = ({
  showFullScreen = true,
  audioPlayerId,
  seekerMarks = [],
  enableCustomFullScreenControls,
  subtitlesAvailable
}: PlayerControlsProps): React.ReactNode => {
  const isFullScreen = useRef(false);

  const {
    state,
    dispatch: mediaPlayerDispatch,
    mediaPlayerRef
  } = useMediaPlayer(audioPlayerId)!;

  const duration = mediaPlayerRef.current?.getDuration() || 0;

  const handleFullScreenClick = () => {
    if (enableCustomFullScreenControls) {
      isFullScreen.current = !isFullScreen.current;
      const wrapper = document.getElementById(`wrapper-${audioPlayerId}`);
      if (wrapper) {
        if (isFullScreen.current) {
          wrapper.requestFullscreen?.();
        } else {
          document.exitFullscreen();
        }
      }
    } else {
      requestFullScreen(mediaPlayerDispatch);
    }
  };

  return (
    <Stack
      px={1}
      py={0}
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={2}
      bgcolor={"common.black"}
    >
      <PlayButton
        audioPlayerId={audioPlayerId}
        iconProps={{
          sx: { height: 24, width: 24, color: "common.white" }
        }}
      />
      <Typography variant="body2" sx={{ color: "common.white" }}>
        {getSeekerTime({
          timeValue: Number(mediaPlayerRef.current?.getCurrentTime() || 0),
          isCyclic: Boolean(duration > 3600)
        })}
      </Typography>
      <Slider
        id={`${audioPlayerId}-seeker`}
        value={state.mediaProgressFraction * 100}
        max={100}
        marks={seekerMarks}
        color="error"
        sx={{
          marginBottom: 0,
          "& .MuiSlider-mark": {
            height: "4px",
            width: "4px",
            borderRadius: "50%",
            opacity: 1,
            transform: "translate(-2px, -50%)",
            backgroundColor: "common.white"
          },
          "& .MuiSlider-rail": {
            backgroundColor: (theme) => theme.palette.common.white,
            height: 8
          },
          "& .MuiSlider-markLabel": {
            top: "auto",
            bottom: "20px"
          },
          "& .MuiSlider-track": {
            backgroundColor: "error.light",
            height: 8,
            border: 0
          },
          "& .MuiSlider-thumb": {
            height: 12,
            width: 12
          }
        }}
        onChange={(_, newValue: number | number[]) => {
          if (Array.isArray(newValue)) {
            return;
          }
          setSeeking(mediaPlayerDispatch, true);
          setProgress(
            mediaPlayerDispatch,
            (newValue / 100) * mediaPlayerRef.current!.getDuration()
          );
        }}
        onChangeCommitted={(_, newValue: number | number[]) => {
          if (Array.isArray(newValue)) {
            return;
          }
          setSeeking(mediaPlayerDispatch, false);
          seekTo(mediaPlayerDispatch, {
            amount: newValue / 100,
            type: "fraction"
          });
        }}
      />

      <Typography variant="body2" sx={{ color: "common.white" }}>
        {getSeekerTime({
          timeValue: duration,
          isCyclic: Boolean(duration > 3600)
        })}
      </Typography>
      <PlayerVolumeControls audioPlayerId={audioPlayerId} />
      {subtitlesAvailable ? (
        <IconButton
          sx={{ p: 1, color: "common.white" }}
          onClick={() => {
            toggleSubtitles(mediaPlayerDispatch);
          }}
        >
          {state.showSubtitles ? (
            <ClosedCaptionRounded
              sx={{
                height: 24,
                width: 24
              }}
            />
          ) : (
            <ClosedCaptionOff sx={{ height: 24, width: 24 }} />
          )}
        </IconButton>
      ) : null}
      {showFullScreen ? (
        <IconButton
          sx={{ p: 1, color: "common.white" }}
          onClick={handleFullScreenClick}
        >
          <FullscreenRounded
            sx={{
              height: 24,
              width: 24
            }}
          />
        </IconButton>
      ) : null}
    </Stack>
  );
};

export default PlayerControls;
