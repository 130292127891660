import FilePlayer, { FilePlayerProps } from "react-player/file";
import {
  useMediaPlayer,
  setVolume,
  setBuffering,
  setMute
} from "../../hooks/useMediaPlayer";
import { setProgress } from "../..";
import {
  useEffect,
  useMemo
  //  useState
} from "react";
import { Box, Typography } from "@mui/material";
import { useMediaPlayerCallbacks } from "../../hooks/useMediaPlayerCallbacks";

interface FilePlayerCustomProps {
  id: string;
  showSubtitles?: boolean;
  type?: "video" | "audio";
  hidden?: boolean;
  fps?: number;
  enableCustomFullScreenControls?: boolean;
}

export type MediaPlayerProps = FilePlayerCustomProps & FilePlayerProps;

export function MediaPlayer({
  type = "video",
  hidden = false,
  ...props
}: MediaPlayerProps) {
  // const [isError, setIsError] = useState(false);

  const {
    state,
    dispatch,
    mediaPlayerRef: mediaRef
  } = useMediaPlayer(props.id)!;
  const isPlaying = state?.isPlaying;
  const seeking = state?.seeking;

  const fpsToUse = props.fps ?? 24;

  const urlToUse = useMemo(
    () =>
      state?.mediaUrl && state?.mediaUrl?.length ? state.mediaUrl : props?.url,
    [state.mediaUrl, props.url]
  );

  const {
    // onError,
    onProgress,
    onBufferEnd,
    onEnded,
    onPause,
    onPlay,
    onReady
  } = useMediaPlayerCallbacks({
    dispatch,
    // setIsError,
    setProgress,
    isPlaying: state?.isPlaying ?? false,
    seeking
  });

  useEffect(() => {
    const currentTrackSrc = props?.config?.tracks?.[0]?.src;
    const videoEl = mediaRef?.current?.getInternalPlayer();
    if (currentTrackSrc && videoEl) {
      const track = videoEl.textTracks[0];
      if (track) {
        if (state.showSubtitles) {
          if (videoEl.querySelector("track")) {
            videoEl.querySelector("track").src = currentTrackSrc;
          }
          track.mode = "showing";
        } else {
          track.mode = "hidden";
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.config?.tracks, state.showSubtitles]);

  const forceAudio = useMemo(() => type === "audio", [type]);
  const displayStyle = useMemo(() => (hidden ? "none" : "flex"), [hidden]);

  if (document?.fullscreenElement?.parentElement?.id === props.id && mediaRef) {
    const internalPlayer = mediaRef?.current?.getInternalPlayer();
    if (internalPlayer) {
      internalPlayer.onvolumechange = function () {
        if (internalPlayer.volume !== state?.volume) {
          setVolume(dispatch, internalPlayer?.volume ?? 1);
        }
        setMute(dispatch, internalPlayer.muted);
      };
    }
  }

  if (
    mediaRef?.current?.getInternalPlayer() &&
    !mediaRef?.current?.getInternalPlayer()?.onprogress
  ) {
    mediaRef.current.getInternalPlayer().onprogress = () => {
      if (mediaRef?.current?.getInternalPlayer().readyState < 4) {
        // console.log("MEDIA PLAYER BUFFERING");
        setBuffering(dispatch, true);
      } else if (mediaRef?.current?.getInternalPlayer().readyState === 4) {
        setBuffering(dispatch, false);
      }
    };
  }

  const onError = (_props: any) => {
    // setIsError(true);
    if (props.onError && typeof props.onError === "function") {
      props.onError(_props);
    }
  };

  const _onReady = (_props: any) => {
    onReady();
    if (props.onReady && typeof props.onReady === "function") {
      props.onReady(_props);
    }
  };

  // if (isError && !hidden) {
  //   return (
  //     <Box
  //       sx={{
  //         display: "flex",
  //         height: "100%",
  //         width: "100%",
  //         alignItems: "center",
  //         justifyContent: "center",
  //         flexGrow: 1
  //       }}
  //     >
  //       <Typography variant="caption">
  //         Could not load media. Please reload the page.
  //       </Typography>
  //     </Box>
  //   );
  // }

  if (!urlToUse && !hidden) {
    console.log("NO MEDIA");
    return (
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography variant="caption">No media to play.</Typography>
      </Box>
    );
  }

  return (
    <>
      <FilePlayer
        onProgress={onProgress}
        onEnded={onEnded}
        ref={mediaRef}
        playing={isPlaying}
        height={"100%"}
        width={"100%"}
        progressInterval={1000 / fpsToUse}
        onPause={onPause}
        onPlay={onPlay}
        onBufferEnd={onBufferEnd}
        url={urlToUse}
        style={{ display: displayStyle }}
        volume={state?.volume ?? undefined}
        muted={state?.muted}
        {...props}
        config={{
          forceAudio: forceAudio,
          forceVideo: true,
          attributes: {
            crossOrigin: "anonymous",
            controlsList: "nodownload"
          },
          ...(props.config ?? {})
        }}
        onReady={_onReady}
        onError={onError}
      />
    </>
  );
}
