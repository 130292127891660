import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { PLANS_CATEGORY, PRICING_DIALOG_STEP } from "@/features/pricing/types";
import { useAuth } from "@/lib/firebase/firebaseHooks";
import {
  setActiveStep,
  setCheckoutPlan,
  setPricingDialog
} from "@/reducers/slices/pricingDialogSlice/pricingDialogSlice";
import { storageService } from "@/utils/storage";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const useRedirectActions = () => {
  const dispatch = useAppDispatch();
  const authUser = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();
  const pricingData = useTypedSelector(
    (state) => state.pricingDialog.pricingData
  );

  const userCurrentPlan = useTypedSelector(
    (state) => state.workspace.workspacePlan.planDetails
  );

  const isPricingDialogOpen = useTypedSelector(
    (state) => state.pricingDialog.open
  );

  const handlePricingDialog = useCallback(() => {
    const planId = searchParams.get("planCategory");
    const plan = pricingData?.find((plan) => plan.category === planId);

    const isEnterprise = userCurrentPlan?.category === PLANS_CATEGORY.CUSTOM;

    if (planId && plan && !isEnterprise) {
      dispatch(
        setCheckoutPlan({
          planId: plan.planId,
          category: plan.category,
          entryPoint: "Redirect",
          maxUser: plan.maxUser,
          noOfProject: plan.noOfProject,
          noWaterMark: plan.noWaterMark,
          qualityAssurance: plan.qualityAssurance,
          selfEditAllowed: plan.selfEditAllowed
        })
      );
      dispatch(setActiveStep(PRICING_DIALOG_STEP.SETUP_FUNDS));
    } else {
      dispatch(setActiveStep(PRICING_DIALOG_STEP.PRICING_PLANS));
      if (planId && !isEnterprise) {
        setSearchParams((prev) => {
          prev.set("planId", planId);
          return prev;
        });
      }
    }
    dispatch(setPricingDialog(true));
  }, [pricingData, userCurrentPlan, dispatch, searchParams, setSearchParams]);

  useEffect(() => {
    if (isPricingDialogOpen) {
      return;
    }
    const intent = searchParams.get("intent");
    if (intent && intent === "pricing") {
      storageService.setItem("showPricingDialog", true, 1000 * 60 * 10);
      const planId = searchParams.get("planCategory");
      if (planId) {
        storageService.setItem(
          "showPricingDialogForPlanId",
          planId,
          1000 * 60 * 10
        );
      }
      if (authUser && userCurrentPlan && !isPricingDialogOpen) {
        handlePricingDialog();
      }
    }
  }, [
    searchParams,
    dispatch,
    authUser,
    isPricingDialogOpen,
    handlePricingDialog,
    userCurrentPlan,
    pricingData
  ]);

  useEffect(() => {
    if (isPricingDialogOpen || !userCurrentPlan) {
      return;
    }
    const intent = searchParams.get("intent");
    const shouldShowPricingDialog = storageService.getItem("showPricingDialog");

    if (
      authUser &&
      !intent &&
      shouldShowPricingDialog &&
      !isPricingDialogOpen
    ) {
      handlePricingDialog();
    }
  }, [
    authUser,
    dispatch,
    isPricingDialogOpen,
    searchParams,
    pricingData,
    handlePricingDialog,
    userCurrentPlan
  ]);

  return null;
};

export default useRedirectActions;
