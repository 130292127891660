import { Button, ButtonProps, CircularProgress, styled } from "@mui/material";
import useId from "@mui/material/utils/useId";
import React from "react";

interface ILoadingButton extends ButtonProps {
  loadingPosition?: "start" | "end" | "center";
  loadingIndicator?: React.ReactNode;
  loading?: boolean;
}

const Wrapper = styled(Button)(() => ({}));

const LoadingButton = ({
  children,
  loadingPosition,
  loadingIndicator,
  loading,
  ...buttonProps
}: ILoadingButton) => {
  const id = useId(buttonProps.id);

  const indicator = loadingIndicator ?? (
    <CircularProgress
      sx={{ mr: 1 }}
      aria-labelledby={id}
      color="inherit"
      size={16}
    />
  );

  const loadingButtonLoadingIndicator = loading ? indicator : null;

  return (
    <Wrapper {...buttonProps} disabled={buttonProps.disabled || loading}>
      {loadingPosition === "end" ? children : loadingButtonLoadingIndicator}
      {loadingPosition === "end" ? loadingButtonLoadingIndicator : children}
    </Wrapper>
  );
};

export default LoadingButton;
