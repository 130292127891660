import { Box, Button, Stack } from "@mui/material";
import DialogMessage from "../Dialog/DialogMessage";
import { ErrorOutlineRounded, Refresh } from "@mui/icons-material";
import { ReactNode } from "react";

interface ErrorFallbackProps {
  message?: string;
  description?: string;
  action?: ReactNode;
}

const ErrorFallback = ({
  message = "Internal server error",
  description = "Our systems have run into an unexpected error, we’re looking into it",
  action
}: ErrorFallbackProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100%"
    >
      <Stack gap={5}>
        <DialogMessage
          icon={<ErrorOutlineRounded sx={{ height: 80, width: 80 }} />}
          message={message}
          description={description}
          backgroundColor="transparent"
        />
        <Box display="flex" justifyContent="center">
          {action ? (
            action
          ) : (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Refresh />}
              size="large"
              onClick={() => window?.location?.reload()}
            >
              Reload the page
            </Button>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default ErrorFallback;
