import { STATUS } from "@/constants/status";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  PaperProps,
  Stack,
  Typography
} from "@mui/material";
import React from "react";
import { TaskAssignment } from "../TaskAssignment";
import { TASK_TYPE } from "@/types/project";
import { CloseRounded, PersonAddRounded } from "@mui/icons-material";

type VARIANT = "popup" | "drawer";

const ReOpenDubContent = ({
  isFetchingPreviousData,
  relatedTags,
  assignedUsers,
  handleReOpenDub,
  setAssignedUsers,
  reopeningDub,
  setIsOpen,
  variant = "drawer",
  paperProps
}: {
  isFetchingPreviousData: STATUS;
  relatedTags: TASK_TYPE[] | undefined;
  assignedUsers: Record<TASK_TYPE, string>;
  handleReOpenDub: (_?: any) => void;
  reopeningDub: boolean;
  setAssignedUsers: React.Dispatch<
    React.SetStateAction<Record<TASK_TYPE, string>>
  >;
  setIsOpen: (_: boolean) => void;
  variant?: VARIANT;
  paperProps?: PaperProps;
}): React.ReactNode => {
  const padding = variant === "popup" ? 4 : 1;

  return (
    <Paper
      elevation={1}
      sx={{
        height: "100%",
        borderRadius: 4
      }}
      {...paperProps}
    >
      {isFetchingPreviousData === STATUS.LOADING ? (
        <Stack
          sx={{
            width: "100%",
            p: 1,
            height: "100%"
          }}
          gap={1}
        >
          <CircularProgress size={24} />
        </Stack>
      ) : (
        <Stack
          sx={{
            width: "100%",
            p: variant === "popup" ? 0 : 1,
            height: "100%"
          }}
          gap={1}
        >
          {variant === "drawer" ? (
            <Stack p={2}>
              <Typography variant="h6">Assign Tasks</Typography>
              <Typography variant="body2">
                Confirm task assignment to Reopen Dub
              </Typography>
            </Stack>
          ) : null}
          {variant === "popup" ? (
            <Stack
              py={1}
              pl={3}
              pr={2}
              direction={"row"}
              alignItems={"center"}
              gap={2}
            >
              <PersonAddRounded sx={{ height: 24, width: 24 }} />
              <Stack>
                <Typography variant="h6">Re-opening Dub</Typography>
                <Typography variant="caption">
                  Confirm task assignment to Reopen Dub
                </Typography>
              </Stack>
              <IconButton
                onClick={() => setIsOpen(false)}
                sx={{
                  ml: "auto"
                }}
              >
                <CloseRounded sx={{ height: 24, width: 24 }} />
              </IconButton>
            </Stack>
          ) : null}
          <Stack gap={2} p={padding} flexGrow={1} flexShrink={0}>
            {relatedTags?.length ? (
              <>
                {relatedTags.map((tag) => (
                  <Box py={0.5} pl={0.5} key={tag}>
                    <TaskAssignment
                      task={tag as TASK_TYPE}
                      assigneeId={assignedUsers[tag as TASK_TYPE]}
                      handleUserSelection={(user) => {
                        setAssignedUsers((state) => ({
                          ...state,
                          [tag]: user?.userId ?? "NOT_ASSIGNED"
                        }));
                      }}
                    />
                  </Box>
                ))}
                <Box py={0.5} pl={0.5}>
                  <TaskAssignment
                    task={TASK_TYPE.MEDIA_MERGE}
                    assigneeId={assignedUsers[TASK_TYPE.MEDIA_MERGE]}
                    handleUserSelection={(user) => {
                      setAssignedUsers((state) => ({
                        ...state,
                        [TASK_TYPE.MEDIA_MERGE]: user?.userId ?? "NOT_ASSIGNED"
                      }));
                    }}
                  />
                </Box>
              </>
            ) : null}
          </Stack>
          <Stack
            p={padding}
            gap={2}
            justifySelf={"baseline"}
            sx={
              variant === "popup"
                ? {
                    flexDirection: "row-reverse"
                  }
                : {}
            }
          >
            {variant === "popup" ? null : (
              <Typography variant="body2">
                Assign tasks to Re-open Dub for processing.
              </Typography>
            )}
            <Button
              variant="contained"
              color={variant === "popup" ? "primary" : "secondary"}
              size="large"
              onClick={() => {
                handleReOpenDub({
                  isInternal: variant === "popup" // @TODO: Fix this
                });
              }}
              disabled={reopeningDub}
              startIcon={reopeningDub ? <CircularProgress size={12} /> : null}
            >
              Assign & Re-Open Dub
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Paper>
  );
};

export default ReOpenDubContent;
