import { styled, Tab, tabClasses } from "@mui/material";

export const MurfTab = styled(Tab)(({ theme }) => ({
  fontWeight: 600,
  minHeight: 36,
  minWidth: 96,
  opacity: 0.7,
  textTransform: "initial",
  fontsize: 13,
  padding: 0,
  color: theme.palette.secondary.main,
  "&:hover": {
    opacity: 1
  },
  [`&.${tabClasses.selected}`]: {
    color: theme.palette.secondary.main,
    opacity: 1
  },
  [theme.breakpoints.up("md")]: {
    minWidth: 120
  }
}));

export default MurfTab;
