import { MurfSnackbar, useSnackbar } from "@/components/elements/MurfSnackbar";
import WorkspaceSelector from "@/components/elements/WorkspaceSelector";
import ClientSideBar from "@/components/layout/ClientSideBar";
import useClientHome from "@/features/clientHome/hooks/useClientHomeFetch";
import { SearchBar } from "@/features/clientSearch";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import React, { Suspense, useCallback, useMemo, useState } from "react";
import MurfAppBar from "../MurfAppBar";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { CREDIT_EVENT_TYPE } from "@/features/credits";
import { Warning } from "@mui/icons-material";
import { setCreditsDialog } from "@/reducers/slices/dialogSlice/dialogSlice";
import LoaderWrapper from "../LoaderWrapper";
import { PLANS_CATEGORY, PRICING_DIALOG_STEP } from "@/features/pricing/types";
import {
  pricingDialogSlice,
  setCheckoutPlan,
  setPricingDialog
} from "@/reducers/slices/pricingDialogSlice/pricingDialogSlice";
import { creditsApi } from "@/features/credits/api";
import { trackMixpanelEvent } from "@/utils/mixpanel";
import { MIXPANEL_EVENTS } from "@/constants/mixpanel";
import { batch } from "react-redux";

const ClientDefaultLayout = ({
  children,
  showSideBar = true
}: {
  children: React.ReactNode;
  showSideBar?: boolean;
}): React.ReactNode => {
  useClientHome();

  const dispatch = useAppDispatch();
  const { showError, showSuccess } = useSnackbar();
  const workspace = useTypedSelector((state) => state.user.activeWorkspaceData);
  const workspaceId = useTypedSelector(
    (state) => state.user.activeWorkspaceData?.workspaceId
  );

  const currentUserPlan = useTypedSelector(
    (state) => state.workspace?.workspacePlan?.planDetails
  );

  const hideCreditText = useMediaQuery("(max-width:1085px)");

  const [lowCreditsVariant, setLowCreditsVariant] = useState<
    CREDIT_EVENT_TYPE | undefined
  >(undefined);
  const [isStatusPending, setIsStatusPending] = useState(false);

  const onContactSales = useCallback(() => {
    if (!workspaceId) return;
    setIsStatusPending(true);
    trackMixpanelEvent(MIXPANEL_EVENTS.CONTACT_SALES, {
      "Entry Point": "Top bar"
    });
    creditsApi
      .contactSales({
        workspaceId,
        eventSource: CREDIT_EVENT_TYPE.PLAN_EXPIRED
      })
      .then(() => {
        setIsStatusPending(false);
        showSuccess(
          "We have received your request. Our team will connect with you shortly"
        );
      })
      .catch((err) => {
        setIsStatusPending(false);
        console.debug(err);
        showError("Unable to contact sales. Please try again later.");
      });
  }, [workspaceId, showError, showSuccess]);

  const availableCredits = useMemo(() => {
    return Math.max(
      0,
      (workspace?.assignedCredits || 0) - (workspace?.usedCredits || 0)
    );
  }, [workspace?.assignedCredits, workspace?.usedCredits]);

  const showLowCreditsInfo = useMemo(() => {
    if (!workspace) {
      return false;
    }
    const { assignedCredits } = workspace;
    if (availableCredits <= 0) {
      setLowCreditsVariant(CREDIT_EVENT_TYPE.CREDITS_EXHAUSTED);
      return true;
    } else if (availableCredits / assignedCredits < 0.2) {
      setLowCreditsVariant(CREDIT_EVENT_TYPE.BUY_CREDITS);
      return true;
    }
    return true;
  }, [workspace, availableCredits]);

  const handleCreditsDialog = () => {
    if (
      !lowCreditsVariant &&
      workspace?.planCategory === PLANS_CATEGORY.CUSTOM
    ) {
      onContactSales();
      return;
    }
    if (
      workspace?.planCategory === PLANS_CATEGORY.PAY_AS_YOU_GO &&
      currentUserPlan
    ) {
      batch(() => {
        dispatch(
          setCheckoutPlan({
            planId: currentUserPlan.planId,
            category: currentUserPlan.category,
            entryPoint: "Top bar",
            maxUser: currentUserPlan.maxUser,
            noOfProject: currentUserPlan.noOfProject,
            noWaterMark: currentUserPlan.noWaterMark,
            qualityAssurance: currentUserPlan.qualityAssurance,
            selfEditAllowed: currentUserPlan.selfEditAllowed
          })
        );
        dispatch(
          pricingDialogSlice.actions.setActiveStep(
            PRICING_DIALOG_STEP.SETUP_FUNDS
          )
        );
        dispatch(setPricingDialog(true));
      });
      return;
    }
    dispatch(setCreditsDialog(true));
  };

  const viewPlans = useCallback(() => {
    dispatch(
      pricingDialogSlice.actions.setActiveStep(
        PRICING_DIALOG_STEP.PRICING_PLANS
      )
    );
    dispatch(pricingDialogSlice.actions.setPricingDialog(true));
    trackMixpanelEvent(MIXPANEL_EVENTS.UPGRADE_START, {
      "Entry Point": "Top bar"
    });
  }, [dispatch]);

  const colorOfCreditsInfo = useMemo(() => {
    if (lowCreditsVariant === CREDIT_EVENT_TYPE.CREDITS_EXHAUSTED) {
      return "error.main";
    }
    if (lowCreditsVariant === CREDIT_EVENT_TYPE.BUY_CREDITS) {
      return "warning.main";
    }
    return "success.main";
  }, [lowCreditsVariant]);

  return (
    <>
      <Box sx={{ maxHeight: "100%", height: "100vh", overflow: "hidden" }}>
        <MurfAppBar>
          <Grid container minHeight={62} gap={2}>
            <Grid
              item
              sx={{
                maxWidth: 240
              }}
            >
              <WorkspaceSelector />
            </Grid>
            <SearchBar />
            <Stack
              direction={"row"}
              gap={2}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                marginLeft: "auto",
                marginRight: 2,
                py: 1
              }}
            >
              <Stack p={0.5} gap={1} direction={"row"} alignItems={"center"}>
                {lowCreditsVariant ? (
                  <Tooltip
                    title={
                      hideCreditText ? (
                        <Typography color={colorOfCreditsInfo} variant="body2">
                          {availableCredits} Credits left
                        </Typography>
                      ) : null
                    }
                  >
                    <Warning
                      sx={{ height: 20, width: 20, color: colorOfCreditsInfo }}
                    />
                  </Tooltip>
                ) : null}
                <Typography
                  color={colorOfCreditsInfo}
                  variant="body2"
                  sx={{
                    display: hideCreditText ? "none" : "block"
                  }}
                >
                  {availableCredits}
                  &nbsp;Credits left
                </Typography>
                {workspace?.planCategory === PLANS_CATEGORY.FREE ? (
                  <Button
                    variant="contained"
                    sx={{ ml: 1 }}
                    onClick={viewPlans}
                  >
                    Upgrade
                  </Button>
                ) : showLowCreditsInfo ? (
                  <Button
                    variant="contained"
                    sx={{ ml: 1 }}
                    onClick={handleCreditsDialog}
                    disabled={isStatusPending}
                    startIcon={
                      isStatusPending ? (
                        <CircularProgress size={16} sx={{ color: "inherit" }} />
                      ) : undefined
                    }
                  >
                    {!lowCreditsVariant &&
                    workspace?.planCategory === PLANS_CATEGORY.CUSTOM
                      ? "Contact Sales"
                      : workspace?.planCategory === PLANS_CATEGORY.PAY_AS_YOU_GO
                      ? "Top-up"
                      : "Buy Credits"}
                  </Button>
                ) : null}
              </Stack>
              <Divider orientation="vertical" sx={{ maxHeight: 40 }} />
            </Stack>
          </Grid>
        </MurfAppBar>
        <Stack
          sx={{
            minHeight: "calc(100vh - 65px)",
            height: "100%"
          }}
          direction={"row"}
        >
          {showSideBar ? <ClientSideBar /> : null}
          <Box
            sx={{
              overflow: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "row",
              width: "100%",
              height: "calc(100% - 65px)",
              px: 4
            }}
          >
            <Suspense fallback={<LoaderWrapper loading />}>{children}</Suspense>
          </Box>
        </Stack>
      </Box>
      <MurfSnackbar />
    </>
  );
};

export default ClientDefaultLayout;
