import { MediaPlayer, playPause, useMediaPlayer } from "@/features/mediaPlayer";
import { Box, BoxProps, CircularProgress, alpha, styled } from "@mui/material";
import React, { useRef } from "react";
import { PlayButton } from "../..";
import PlayerControls from "./PlayerControls";
import { DubMediaPlayerProps } from "../types";
import { AudiotrackRounded } from "@mui/icons-material";

const OnVideoPlayBtn = styled(Box)<
  BoxProps & Pick<DubMediaPlayerProps, "type">
>(({ theme, type }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  height: "calc(100% - 36px)",
  minHeight: 250,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor:
    type === "audio" ? "common.black" : alpha(theme.palette.common.black, 0.5),
  opacity: 1,
  cursor: "pointer"
}));

const OnVideoPlayBtnHover = styled(OnVideoPlayBtn)(() => ({
  opacity: 0,
  transition: "opacity 0.2s ease",
  "&:hover": {
    opacity: 1
  }
}));

const AudioThumbnail = styled(Box)(() => ({
  backgroundColor: "background.default",
  color: "white",
  position: "absolute",
  top: 0,
  left: 0,
  minHeight: 320,
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}));

const VideoThumbnail = styled(Box)(() => ({
  position: "absolute",
  inset: 0,
  minHeight: 320,
  height: "100%",
  width: "100%",
  alignItems: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover"
}));

const Player = ({
  mediaSrc,
  type,
  playerProps,
  mediaPlayerId,
  seekerMarks,
  boxStyleProps,
  mediaPlayerProps,
  playerWrapperStyles,
  thumbnailSrc
}: DubMediaPlayerProps): React.ReactNode => {
  // ref used to show and hide the video thumbnail. if video is not played then show thumbnail
  const isPlayed = useRef(false);
  const { dispatch, state } = useMediaPlayer(mediaPlayerId)!;

  if (state.isPlaying) {
    isPlayed.current = true;
  }

  return (
    <>
      <Box
        position={"relative"}
        sx={{
          display: "flex",
          flexDirection: "column",
          ...boxStyleProps
        }}
        id={`wrapper-${mediaPlayerId}`}
      >
        <Box
          sx={{
            // height: "100%",
            minHeight: 250,
            aspectRatio: "16/9",
            position: "relative",
            ...playerWrapperStyles
          }}
        >
          <MediaPlayer
            id={mediaPlayerId}
            url={mediaSrc}
            type={type}
            loop={false}
            onPlay={() => {
              isPlayed.current = true;
              mediaPlayerProps?.onPlay?.();
            }}
            {...mediaPlayerProps}
          />
          {type === "audio" ? (
            <AudioThumbnail {...playerProps}>
              <AudiotrackRounded
                sx={{
                  height: 64,
                  width: 64,
                  color: "text.secondary"
                }}
              />
            </AudioThumbnail>
          ) : null}
          {thumbnailSrc && !isPlayed.current ? (
            <VideoThumbnail
              sx={{ backgroundImage: `url('${thumbnailSrc}')` }}
            />
          ) : null}
        </Box>

        {state.buffering ? (
          <OnVideoPlayBtn type={type}>
            <CircularProgress
              sx={{
                height: 64,
                width: 64,
                color: "common.white"
              }}
            />
          </OnVideoPlayBtn>
        ) : (
          <OnVideoPlayBtnHover
            onClick={() => {
              isPlayed.current = true;
              playPause(dispatch);
            }}
            type={type}
          >
            <PlayButton
              audioPlayerId={mediaPlayerId}
              iconProps={{
                sx: {
                  height: 64,
                  width: 64,
                  color: "common.white"
                }
              }}
            />
          </OnVideoPlayBtnHover>
        )}

        <Box sx={{ width: "100%" }}>
          <PlayerControls
            showFullScreen={type === "video"}
            audioPlayerId={mediaPlayerId}
            seekerMarks={seekerMarks}
            enableCustomFullScreenControls={
              mediaPlayerProps?.enableCustomFullScreenControls
            }
            subtitlesAvailable={
              type === "video" &&
              Boolean(mediaPlayerProps?.config?.tracks?.length)
            }
          />
        </Box>
      </Box>
    </>
  );
};

export default Player;
