export const isLoggedInCheck = (authUser: any) => !!authUser;

/**
 *
 * @param {string} email
 * @returns {string}
 */
export const getEmailDomain = (email: string) => {
  if (!email) return "";
  return email.split("@")[1] ? email.split("@")[1] : "";
};

export const isCorporate = (email: string) => {
  if (email) {
    const domain = email.substring(email.indexOf("@") + 1);
    const domainSegment = domain && domain.substring(0, domain.indexOf("."));
    if (
      ![
        "gmail",
        "yahoo",
        "hotmail",
        "aol",
        "outlook",
        "msn",
        "icloud",
        "protonmail",
        "me",
        "mac",
        "live",
        "yandex",
        "tutanota",
        "web",
        "btinternet",
        "ymail",
        "mail",
        "murf",
        "googlemail",
        "qq"
      ].includes(domainSegment)
    ) {
      return domain;
    }
  }
  return undefined;
};
