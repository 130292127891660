import { APIResponse } from "@/types/apiResponse";
import { PaymentMethod } from "@stripe/stripe-js";

export type LastPaymentStatus =
  | "QUEUED"
  | "PROCESSING"
  | "COMPLETED"
  | "FAILED";

export type PricingPlan = {
  category: PLANS_CATEGORY;
  planId: string;
  noOfProject: number;
  maxUser: number;
  qualityAssurance: boolean;
  selfEditAllowed: boolean;
  noWaterMark: boolean;
  creditsPerUnit?: number;
  characterLimitFlexible?: boolean;
  autoPayEnabled?: boolean;
  autoPayLowerLimit?: number;
  autoPayUpperLimit?: number;
  lastAutoPayTriggerCalledMillis?: number;
  autoPayStatus?: LastPaymentStatus;
};

export type AutoPayData = {
  autoPayEnabled: boolean;
  autoPayLowerLimit: number | null;
  autoPayUpperLimit: number | null;
};

export type CheckoutPlan = PricingPlan & {
  entryPoint: string;
};

export type PaymentMethodSummary = {
  billingCity: string;
  billingCountry: string;
  billingLine1: string;
  billingLine2: string;
  billingName: string;
  billingPostalCode: string;
  billingState: string;
  cardBrand: string;
  cardExpMonth: number;
  cardExpYear: number;
  cardLast4: string;
  paymentMethodId: string;
  type: string;
};

export type PreviewLineItem = {
  amount: number;
  currency: string;
  description: string;
  periodStart: string;
  periodEnd: string;
  discountLine: boolean;
};

export type CountryWiseTax = {
  stripeCode: string;
  country: string;
  regex: string | null;
  description: string;
  example: string;
  countryCode: string;
};

export type PreviewData = APIResponse<{
  clientSecret: string | null;
  total: number;
  subtotal: number;
  currency: string;
  tax: number;
  discount: number;
  previewLineItems: PreviewLineItem[];
  countryWiseTaxDetails: Record<string, CountryWiseTax>;
  defaultPaymentMethodId: string | null;
  paymentMethodSummaries: PaymentMethodSummary[];
  purchaseAsBusinessEnabled: boolean;
  upgradePreviewGeneratedUsingInAccount: boolean;
}>;

export const enum PLANS_CATEGORY {
  FREE = "DUB_FREE_PLAN",
  PAY_AS_YOU_GO = "DUB_PAY_AS_YOU_GO_PLAN",
  CUSTOM = "DUB_CUSTOM_PLAN"
}

export const enum PRICING_DIALOG_STEP {
  PRICING_PLANS = "PRICING_PLANS",
  SETUP_FUNDS = "SETUP_FUNDS",
  CHECKOUT = "CHECKOUT",
  PAYMENT_SUCCESS = "PAYMENT_SUCCESS"
}

export const enum PAYMENT_MODES {
  IDLE = "IDLE",
  NEW = "NEW",
  SAVED = "SAVED"
}

export type PlanSummary<
  T extends string | ((arg: PricingPlan) => string) = string
> = {
  text: T;
  icon?: React.ReactNode;
  tooltipText?: string;
};

export type PricingPreviewPayload = {
  pricingPlan: string;
  topupValue: number;
  abortController?: AbortController;
  billingCity?: string;
  billingState?: string;
  billingCountry?: string;
  billingPostalCode?: string;
  taxType?: string;
  taxValue?: string;
};

export type PricingUpgradePayload = {
  pricingPlan: string;
  topupValue: number;
  couponCode?: string;
  paymentMethod: string | PaymentMethod | null | undefined;
  businessName?: string;
  taxType?: string;
  taxValue?: string;
  upgradePreviewGeneratedUsingInAccount?: boolean;
};

export type BusinessDetails = {
  businessName: string;
  taxId: string;
  taxType: string;
};
