import { STATUS } from "@/constants/status";
import {
  CHECKOUT_COUPON_ERRORS,
  CHECKOUT_PREVIEW_ERRORS
} from "@/features/pricing/constants";
import {
  BusinessDetails,
  CheckoutPlan,
  CountryWiseTax,
  PAYMENT_MODES,
  PreviewData,
  PRICING_DIALOG_STEP,
  PricingPlan
} from "@/features/pricing/types";
import { pricingThunks } from "@/reducers/thunks/pricingThunks";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const getCountryTaxData = (previewData: Record<string, CountryWiseTax>) => {
  const obj: Record<string, CountryWiseTax[]> = {};
  if (Object.keys(previewData)?.length) {
    Object.keys(previewData).forEach((key) => {
      const country = previewData[key];
      if (!obj[country.countryCode]) obj[country.countryCode] = [];
      obj[country.countryCode].push(country);
    });
  }

  return obj;
};

type PricingDialogState = {
  open: boolean;
  pricingData: PricingPlan[];
  pricingApiStatus: STATUS;
  entryPoint: string;
  activeStep: PRICING_DIALOG_STEP;
  upgradeStarted: boolean;
  calculateCreditDialogOpen: boolean;
  calculatedCreditEstimation: number;
  checkoutDetails: {
    checkoutPlan?: CheckoutPlan;
    topupValue: number;
    previewStatus: STATUS;
    previewData?: PreviewData;
    previewError?: string;
    errorMessage?: string;
    paymentError?: string;
    paymentStatus: STATUS;
    paymentMode: PAYMENT_MODES;
    selectedCardId: string;
    errorCard: string;
    address: {
      city: string;
      state: string;
      country: string;
      postal_code: string;
      line1: string;
      line2: string | null;
    };
    isBusiness: boolean;
    businessDetails: BusinessDetails;
    countryWiseTaxDetails?: Record<string, CountryWiseTax[]>;
  };
};

const initialState: PricingDialogState = {
  open: false,
  pricingData: [],
  entryPoint: "",
  pricingApiStatus: STATUS.IDLE,
  activeStep: PRICING_DIALOG_STEP.PRICING_PLANS,
  upgradeStarted: false,
  calculateCreditDialogOpen: false,
  calculatedCreditEstimation: 0,
  checkoutDetails: {
    topupValue: 100,
    previewStatus: STATUS.IDLE,
    paymentStatus: STATUS.IDLE,
    paymentMode: PAYMENT_MODES.IDLE,
    selectedCardId: "",
    errorCard: "",
    address: {
      city: "",
      state: "",
      country: "",
      postal_code: "",
      line1: "",
      line2: ""
    },
    isBusiness: false,
    businessDetails: {
      businessName: "",
      taxId: "",
      taxType: ""
    }
  }
};

export const pricingDialogSlice = createSlice({
  name: "pricingDialog",
  initialState,
  reducers: {
    resetPricingDialogState: (state) => {
      return {
        ...initialState,
        pricingData: state.pricingData,
        pricingApiStatus: state.pricingApiStatus
      };
    },
    setEntryPoint: (state, action: PayloadAction<string>) => {
      state.entryPoint = action.payload;
    },
    setPricingDialog: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
      state.calculatedCreditEstimation = 0;
    },
    setCheckoutPlan: (state, action: PayloadAction<CheckoutPlan>) => {
      state.checkoutDetails.checkoutPlan = action.payload;
    },
    setActiveStep: (state, action: PayloadAction<PRICING_DIALOG_STEP>) => {
      state.activeStep = action.payload;
    },
    setTopupValue: (state, action: PayloadAction<number>) => {
      state.checkoutDetails.topupValue = action.payload;
    },
    setPreviewStatus: (state, action: PayloadAction<STATUS>) => {
      state.checkoutDetails.previewStatus = action.payload;
    },
    setAddress: (
      state,
      action: PayloadAction<PricingDialogState["checkoutDetails"]["address"]>
    ) => {
      state.checkoutDetails.address = action.payload;
    },
    setIsBusiness: (state, action: PayloadAction<boolean>) => {
      state.checkoutDetails.isBusiness = action.payload;
    },
    setUpgradeStarted: (state, action: PayloadAction<boolean>) => {
      state.upgradeStarted = action.payload;
    },
    setSelectedCardId: (state, action: PayloadAction<string>) => {
      state.checkoutDetails.selectedCardId = action.payload;
    },
    setPaymentMode: (state, action: PayloadAction<PAYMENT_MODES>) => {
      state.checkoutDetails.paymentMode = action.payload;
    },
    setPaymentError: (state, action: PayloadAction<string>) => {
      state.checkoutDetails.paymentError = action.payload;
    },
    setPaymentStatus: (state, action: PayloadAction<STATUS>) => {
      state.checkoutDetails.paymentStatus = action.payload;
    },
    setErrorCard: (state, action: PayloadAction<string>) => {
      state.checkoutDetails.errorCard = action.payload;
    },
    setCalculateCreditDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.calculateCreditDialogOpen = action.payload;
    },
    setCalculatedCreditEstimation: (state, action: PayloadAction<number>) => {
      state.calculatedCreditEstimation = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(pricingThunks.fetchPricingPlans.pending, (state) => {
      state.pricingApiStatus = STATUS.LOADING;
    });
    builder.addCase(
      pricingThunks.fetchPricingPlans.fulfilled,
      (state, action) => {
        state.pricingApiStatus = STATUS.SUCCESS;
        state.pricingData = action.payload;
      }
    );
    builder.addCase(pricingThunks.fetchPricingPlans.rejected, (state) => {
      state.pricingApiStatus = STATUS.ERROR;
    });
    builder.addCase(pricingThunks.fetchPricingPreview.pending, (state) => {
      state.checkoutDetails.previewStatus = STATUS.LOADING;
    });
    builder.addCase(
      pricingThunks.fetchPricingPreview.fulfilled,
      (state, action) => {
        console.log("pricingThunks.fetchPricingPreview.fulfilled", action);
        if (action.payload.responseData.clientSecret) {
          state.checkoutDetails.previewData = action.payload;
          state.checkoutDetails.previewStatus = STATUS.SUCCESS;
          state.checkoutDetails.previewError = action.payload.responseCode;
          state.checkoutDetails.errorMessage = action.payload.responseMessage;

          state.checkoutDetails.countryWiseTaxDetails = getCountryTaxData(
            action.payload.responseData.countryWiseTaxDetails
          );

          if (state.checkoutDetails.paymentMode === PAYMENT_MODES.IDLE) {
            if (action.payload.responseData.defaultPaymentMethodId) {
              state.checkoutDetails.paymentMode = PAYMENT_MODES.SAVED;
              state.checkoutDetails.selectedCardId =
                action.payload.responseData.defaultPaymentMethodId;
            } else {
              if (action.payload.responseData.paymentMethodSummaries.length) {
                state.checkoutDetails.paymentMode = PAYMENT_MODES.SAVED;
              } else {
                state.checkoutDetails.paymentMode = PAYMENT_MODES.NEW;
              }
            }
          }
        } else {
          if (state.checkoutDetails.previewData) {
            state.checkoutDetails.previewData.responseCode =
              action.payload.responseCode;
          }
          state.checkoutDetails.previewError = action.payload.responseCode;
          state.checkoutDetails.errorMessage = action.payload.responseMessage;
          if (CHECKOUT_COUPON_ERRORS[action.payload.responseCode]) {
            state.checkoutDetails.previewStatus = STATUS.SUCCESS;
          }
        }
        // console.log("fetchPricingPreview.fulfilled", action.payload);
        if (action.payload.responseCode === STATUS.SUCCESS) {
          state.checkoutDetails.paymentError = "";
        } else if (
          action.payload.responseCode ===
          CHECKOUT_PREVIEW_ERRORS.INVALID_TAX_VALUE
        ) {
          state.checkoutDetails.paymentError =
            "Your Tax ID is invalid, please re-verify your Tax ID and try again.";
        }
      }
    );
    builder.addCase(
      pricingThunks.fetchPricingPreview.rejected,
      (state, action) => {
        if (action.error.code !== "ERR_CANCELED") {
          state.checkoutDetails.previewStatus = STATUS.ERROR;
        }
      }
    );
    builder.addCase(
      pricingThunks.setBusinessDetails.fulfilled,
      (state, action) => {
        state.checkoutDetails.businessDetails = action.payload;
      }
    );
  }
});

export const {
  resetPricingDialogState,
  setEntryPoint,
  setPricingDialog,
  setCheckoutPlan,
  setActiveStep,
  setTopupValue,
  setPreviewStatus,
  setIsBusiness,
  setUpgradeStarted,
  setSelectedCardId,
  setPaymentMode,
  setPaymentError,
  setPaymentStatus,
  setErrorCard,
  setAddress,
  setCalculateCreditDialogOpen,
  setCalculatedCreditEstimation
} = pricingDialogSlice.actions;

export default pricingDialogSlice.reducer;
