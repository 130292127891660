import { Stack } from "@mui/material";
import { ReactNode } from "react";
import PromotionalBanner from "./PromotionalBanner";

const ShareDubLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Stack height="100vh">
      {/* <ShareTopBar /> */}
      <PromotionalBanner />
      {children}
    </Stack>
  );
};

export default ShareDubLayout;
