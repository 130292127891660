const STORAGE_PREFIX = "murf_";
export const USER_INVITE_PENDING = "user__invite__pending";
export const START_FLOW_COMPLETED = "start__flow__completed";

const storagePrefix = (key: string) => `${STORAGE_PREFIX}${key}`;

const storageService = {
  getItem: (key: string) => {
    const value = localStorage.getItem(storagePrefix(key));
    if (value) {
      try {
        const parsedValue = JSON.parse(value);
        if (parsedValue.ttl && parsedValue.ttl > Date.now()) {
          return parsedValue.data;
        } else {
          storageService.removeItem(storagePrefix(key));
        }
      } catch (error) {
        return value;
      }
    }

    return value;
  },
  setItem: (key: string, data: any, ttl: number | undefined = undefined) => {
    if (ttl && typeof ttl === "number") {
      const now = new Date();
      const item = {
        data,
        ttl: now.getTime() + ttl
      };
      localStorage.setItem(storagePrefix(key), JSON.stringify(item));
    } else {
      localStorage.setItem(storagePrefix(key), data);
    }
  },
  removeItem: (key: string) => {
    localStorage.removeItem(storagePrefix(key));
  },
  clear: () => {
    localStorage.clear();
  }
};

export { storageService };
