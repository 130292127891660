import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SnackBarState, UploadFilesState } from "./types";
import { translationVoThunks } from "@/reducers/thunks/translationVo";
import { WORKSPACE_ROLES } from "@/types/workspace";

export enum UpgradeDialogType {
  PROJECT_LIMIT_REACHED = "PROJECT_LIMIT_REACHED",
  REMOVE_WATERMARK = "REMOVE_WATERMARK",
  ADD_MORE_USERS = "ADD_MORE_USERS",
  EDIT_DUB = "EDIT_DUB"
}

export const UpgradeDialogTypeMap = {
  [UpgradeDialogType.PROJECT_LIMIT_REACHED]: "Project Limit Reached",
  [UpgradeDialogType.REMOVE_WATERMARK]: "Remove Watermark",
  [UpgradeDialogType.ADD_MORE_USERS]: "Add More Users",
  [UpgradeDialogType.EDIT_DUB]: "Edit Dub"
};

interface DialogState {
  pronunciationDialog: { open: boolean };
  pronunciationInfo: { open: boolean };
  emphasisDialog: { open: boolean };
  submitTVoDialog: { open: boolean };
  discardTVoChangesDialog: {
    open: boolean;
    /**
     * denotes if user is trying to access wrong dubVersion (other than draft version)
     */
    isWrongVersion: boolean;
    /**
     * dub version that is required from user (through link)
     */
    askedDubVersion: string | null;
  };
  transferOwnershipDialog: { open: boolean };
  removeUserFromWorkspaceDialog: {
    open: boolean;
    email: string;
    userName: string;
    workspaceName: string;
    role?: WORKSPACE_ROLES;
  };
  snackBar: SnackBarState;
  workspaceDrawer: { open: boolean; workspaceId?: string };
  uploadFilesModal: {
    isListOfFilesOpen: boolean;
  };
  addMoreFilesModal: {
    open: boolean;
  };
  startTaskDialog: {
    open: boolean;
  };
  creditsDialog: {
    open: boolean;
  };
  upgradeDialog: {
    open: boolean;
    type: UpgradeDialogType;
  };
  markAsDoneDubPreviewDialog: {
    open: boolean;
  };
  issueDialog: {
    tvoPage: {
      openForBlockId: string;
    };
  };
}

const initialState: DialogState = {
  pronunciationDialog: {
    open: false
  },
  pronunciationInfo: {
    open: false
  },
  emphasisDialog: {
    open: false
  },
  submitTVoDialog: {
    open: false
  },
  discardTVoChangesDialog: {
    open: false,
    isWrongVersion: false,
    askedDubVersion: null
  },
  transferOwnershipDialog: {
    open: false
  },
  removeUserFromWorkspaceDialog: {
    open: false,
    email: "",
    userName: "",
    workspaceName: ""
  },
  snackBar: {
    open: false,
    message: "",
    type: undefined
  },
  workspaceDrawer: {
    open: false
  },
  uploadFilesModal: {
    isListOfFilesOpen: false
  },
  startTaskDialog: {
    open: false
  },
  creditsDialog: {
    open: false
  },
  upgradeDialog: {
    open: false,
    type: UpgradeDialogType.PROJECT_LIMIT_REACHED
  },
  addMoreFilesModal: {
    open: false
  },
  markAsDoneDubPreviewDialog: {
    open: false
  },
  issueDialog: {
    tvoPage: {
      openForBlockId: ""
    }
  }
};

const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    setPronunciationDialog: (state, action) => {
      state.pronunciationDialog = {
        open: action.payload.open
      };
    },
    setPronunciationInfo: (state, action: PayloadAction<boolean>) => {
      state.pronunciationInfo = {
        open: action.payload
      };
    },
    setEmphasisDialog: (state, action) => {
      state.emphasisDialog = {
        open: action.payload.open
      };
    },
    setSubmitTVoDialog: (state, action: PayloadAction<{ open: boolean }>) => {
      state.submitTVoDialog = action.payload;
    },
    setDiscardTVoChangesDialog: (
      state,
      action: PayloadAction<Partial<DialogState["discardTVoChangesDialog"]>>
    ) => {
      state.discardTVoChangesDialog = {
        ...state.discardTVoChangesDialog,
        ...action.payload
      };
    },
    setTransferOwnershipDialog: (
      state,
      action: PayloadAction<{ open: boolean }>
    ) => {
      state.transferOwnershipDialog.open = action.payload.open;
    },
    setRemoveUserFromWorkspaceDialog: (
      state,
      action: PayloadAction<{
        open: boolean;
        email: string;
        userName: string;
        workspaceName: string;
        role?: WORKSPACE_ROLES;
      }>
    ) => {
      state.removeUserFromWorkspaceDialog = action.payload;
    },
    setSnackBar: (state, action: PayloadAction<SnackBarState>) => {
      state.snackBar = {
        open: action.payload.open,
        message: action.payload.message,
        type: action.payload.type
      };
    },
    setWorkspaceDrawer: (
      state,
      action: PayloadAction<DialogState["workspaceDrawer"]>
    ) => {
      state.workspaceDrawer = {
        open: action.payload.open,
        workspaceId: action.payload.workspaceId
      };
    },
    setUploadFilesModal: (state, action: PayloadAction<UploadFilesState>) => {
      state.uploadFilesModal.isListOfFilesOpen =
        action.payload.isListOfFilesOpen;
    },
    setStartTaskDialog: (
      state,
      action: PayloadAction<DialogState["startTaskDialog"]>
    ) => {
      state.startTaskDialog = action.payload;
    },
    setCreditsDialog: (state, action: PayloadAction<boolean>) => {
      state.creditsDialog.open = action.payload;
    },
    setUpgradeDialog: (
      state,
      action: PayloadAction<{
        open: boolean;
        type: UpgradeDialogType;
      }>
    ) => {
      state.upgradeDialog = action.payload;
    },
    setAddMoreFilesModal: (state, action: PayloadAction<boolean>) => {
      state.addMoreFilesModal.open = action.payload;
    },
    setMarkAsDoneDubPreviewDialog: (state, action: PayloadAction<boolean>) => {
      state.markAsDoneDubPreviewDialog.open = action.payload;
    },
    setIssueDialogOnTvoPage: (state, action: PayloadAction<string>) => {
      state.issueDialog.tvoPage.openForBlockId = action.payload;
    }
  },
  extraReducers: (builder) =>
    builder.addCase(translationVoThunks.resetState.fulfilled, (state) => {
      // cleaning state for translation vo page
      state.discardTVoChangesDialog = initialState.discardTVoChangesDialog;
      state.submitTVoDialog = initialState.submitTVoDialog;
      state.startTaskDialog = initialState.startTaskDialog;
    })
});

export const {
  setPronunciationDialog,
  setSnackBar,
  setEmphasisDialog,
  setPronunciationInfo,
  setSubmitTVoDialog,
  setDiscardTVoChangesDialog,
  setTransferOwnershipDialog,
  setRemoveUserFromWorkspaceDialog,
  setWorkspaceDrawer,
  setUploadFilesModal,
  setStartTaskDialog,
  setCreditsDialog,
  setUpgradeDialog,
  setAddMoreFilesModal,
  setMarkAsDoneDubPreviewDialog,
  setIssueDialogOnTvoPage
} = dialogSlice.actions;

export default dialogSlice.reducer;
